@charset "UTF-8";
:root {
  --color-primary: #61b7d9;
  --color-primary-light: #e7f4f8;
  --color-primary-bg: #e7f4f8;
  --color-secondary: #ffd500;
  --color-secondary-bg: #fffad6;
  --color-tertiary: #ff7053;
  --color-cv-primary: #ffd500;
  --color-cv-primary-dark: #e59900;
  --color-cv-secondary: #ff7053;
  --color-cv-secondary-dark: #b84730;
  --color-bg: #e7f4f8;
  --color-bg02: #f2f2f2;
  --color-bg03: #fffad6;
  --color-bg-dark: #393939;
  --color-bronze: #ad7723;
  --color-slider-arrow: var(--color-black);
  --color-gradation-01: linear-gradient(355deg, rgb(255 255 255) 20%, #7abee3 60%, #292dbf 90%);
  --color-gradation-02: linear-gradient(0deg, rgb(255 255 255 / 0%) 0%, rgb(241 242 246) 85%);
  --color-button-solid: var(--color-primary);
  --color-button-solid-dark: #177ea7;
  --color-button-gradient01: linear-gradient(to right, #000 0%, #000 50%, var(--color-cv-primary) 90%);
  --color-button-solid-hover: #88c3db;
  --color-cv-primary-hover: #ffe76f;
  --color-cv-primary-text-hover: #656565;
  --color-cv-secondary-hover: #ff927c;
  --color-cv-secondary-text-hover: #fff;
  --change-color-bg: #fff;
  --change-color-text: #fff;
  --change-color-fill: #fff;
  --change-color-border: #000;
  --change-translate-x: calc(5 / 16 * 1rem);
  --color-black: #000;
  --color-white: #fff;
  --color-gray-25: #252525;
  --color-gray-33: #333;
  --color-gray-5e: #5e5e5e;
  --color-gray-65: #656565;
  --color-gray-66: #666;
  --color-gray-70: #707070;
  --color-gray-80: #808080;
  --color-gray-99: #999;
  --color-gray-af: #afafaf;
  --color-gray-cc: #ccc;
  --color-gray-dd: #ddd;
  --color-gray-ee: #eee;
  --color-gray-e0: #e0e0e0;
  --color-gray-e5: #e5e5e5;
  --color-gray-f4: #f4f4f4;
  --color-gray-f5: #f5f5f5;
  --color-gray-f8: #f8f8f8;
  --color-gray-f9: #f9f9f9;
  --anime-duration: 0;
  --anime-function: ease-out;
  --font-size-base: 15;
  --color-text-base: #262626;
  --color-text-gray: #999;
  --font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Hiragino Sans", meiryo, sans-serif;
  --font-family-en: "Lato", sans-serif;
  --line-height-large: 2;
  --line-height-largeish: 1.9;
  --line-height-base: 1.75;
  --line-height-smallish: 1.6;
  --line-height-small: 1.4;
  --line-height-heading-base: 1.5;
  --letter-spacing-large: 0.04em;
  --letter-spacing-base: 0em;
  --letter-spacing-heading-large: 0.06em;
  --letter-spacing-heading-base: 0.04em;
  --letter-spacing-heading-small: 0em;
  --page-wrap-px: 10;
  /* stylelint-disable-next-line media-query-no-invalid */
  --hover-image-opacity: 0.7;
  --hover-text-color: var(--color-gray-65);
}
@media (min-width: 768px) {
  :root {
    --font-size-base: 16;
    --page-wrap-px: 28;
    --content-width-base: 1200;
  }
}

/* =========================================================
reset
========================================================= */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

a {
  text-decoration: none;
  cursor: pointer;
}

input,
select {
  vertical-align: middle;
}

strong {
  font-weight: normal;
}

/* =========================================================
web-font
========================================================= */
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/font/NotoSansCJKjp-Regular-sub-j1.otf") format("otf"), url("/assets/font/NotoSansCJKjp-Regular-sub-j1.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/font/NotoSansCJKjp-bold-sub-j1.otf") format("otf"), url("/assets/font/NotoSansCJKjp-bold-sub-j1.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/font/Lato-Bold.woff2") format("woff2"), url("/assets/font/Lato-Bold.woff") format("woff");
  font-display: swap;
}
/* =========================================================
base
========================================================= */
html {
  scrollbar-gutter: stable;
  font-size: calc(15.5/375 * 100vw);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width:767px) {
  html {
    font-size: 16px;
  }
}

html,
body {
  width: 100%;
  height: auto;
}

body {
  font-family: var(--font-family);
  font-weight: 400;
  line-height: var(--line-height-base);
  color: var(--color-text-base);
  letter-spacing: var(--letter-spacing-base);
  text-size-adjust: 100%;
  font-size: calc(var(--font-size-base) / 16 * 1rem);
}
body.is-fixed {
  position: fixed;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  img {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    image-rendering: -webkit-optimize-contrast;
  }
}

svg {
  vertical-align: bottom;
}

a {
  color: var(--color-text-base);
}

figure {
  line-height: 0;
}

button {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  color: var(--color-text-base);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  line-height: var(--line-height-heading-base);
  letter-spacing: var(--letter-spacing-heading-base);
}

em {
  font-style: normal;
}

.is-en {
  font-family: var(--font-family-en);
  font-weight: 700;
}

/* =========================================================
libs: splide
========================================================= */
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  margin: 0 !important;
  opacity: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s infinite linear;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__play {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.splide__arrow {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(36 / 16 * 1rem);
  height: calc(36 / 16 * 1rem);
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
  transform: translateY(-50%);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .splide__arrow {
    top: 50%;
    width: calc(45 / 16 * 1rem);
    height: calc(45 / 16 * 1rem);
  }
}
.splide__arrow svg {
  width: calc(10 / 16 * 1rem);
  height: auto;
  fill: var(--color-slider-arrow);
}
.splide__arrow--next {
  right: calc(-20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .splide__arrow--next {
    right: calc(-35 / 16 * 1rem);
  }
}
.splide__arrow--prev {
  left: calc(-20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .splide__arrow--prev {
    left: calc(-35 / 16 * 1rem);
  }
}
.splide__arrow--prev svg {
  transform: rotate(180deg);
}
.splide__pagination {
  display: flex;
  gap: calc(15 / 16 * 1rem);
  align-items: center;
  justify-content: center;
  margin-top: calc(15 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .splide__pagination {
    gap: calc(15 / 16 * 1rem);
    margin-top: calc(20 / 16 * 1rem);
  }
}
.splide__pagination__page {
  width: calc(10 / 16 * 1rem);
  height: calc(10 / 16 * 1rem);
  cursor: pointer;
  background-color: var(--color-gray-cc);
  border: solid 2px var(--color-white);
  border-radius: 50%;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .splide__pagination__page {
    width: calc(10 / 16 * 1rem);
    height: calc(10 / 16 * 1rem);
  }
}
.splide__pagination__page.is-active {
  background-color: var(--color-white);
  border: solid 2px var(--color-slider-arrow);
  opacity: 1;
}

.pc-only {
  display: none;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .pc-only {
    display: block;
  }
}

.sp-only {
  display: block; /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .sp-only {
    display: none;
  }
}

.l-container {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .l-container {
    min-width: 1180px;
  }
}

#top .l-main {
  position: relative;
  z-index: 2;
}

.l-section {
  position: relative;
}
.l-section.is-bg-gradient-gray {
  background: var(--color-gradation-02);
  border-top: solid 1px var(--color-gray-e0);
}
.l-section.is-bg-gradient-gray::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  height: 8px;
  content: "";
  background-color: var(--color-bg02);
}

.l-wrap {
  position: relative;
  padding: 0 calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .l-wrap {
    padding: 0 calc(20 / 16 * 1rem);
  }
}

.l-content {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.l-content.is-w-full {
  width: auto;
  margin-right: calc(-20 / 16 * 1rem);
  margin-left: calc(-20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .l-content.is-w-full {
    margin-right: calc(-20 / 16 * 1rem);
    margin-left: calc(-20 / 16 * 1rem);
  }
}
.l-content.is-w-max {
  max-width: none;
}
.l-content.is-w-1440 {
  max-width: calc(1440 / 16 * 1rem);
}
.l-content.is-w-1200 {
  max-width: calc(1200 / 16 * 1rem);
}
.l-content.is-w-1120 {
  max-width: calc(1120 / 16 * 1rem);
}
.l-content.is-w-1040 {
  max-width: calc(1040 / 16 * 1rem);
}
.l-content.is-w-1000 {
  max-width: calc(1000 / 16 * 1rem);
}
.l-content.is-w-800 {
  max-width: calc(800 / 16 * 1rem);
}
.l-content.is-pd-x-double {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .l-content.is-pd-x-double {
    padding: 0 calc(12 / 16 * 1rem);
  }
}
.l-content.is-pd-x-triple {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .l-content.is-pd-x-triple {
    padding: 0 calc(24 / 16 * 1rem);
  }
}

.l-inner.is-pd-x-double {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .l-inner.is-pd-x-double {
    padding: 0 calc(12 / 16 * 1rem);
  }
}
.l-inner.is-pd-x-triple {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .l-inner.is-pd-x-triple {
    padding: 0 calc(24 / 16 * 1rem);
  }
}

.l-separate {
  display: grid;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .l-separate.is-quarter {
    grid-template-columns: 21% 1fr;
    gap: 4%;
  }
}
.l-separate.is-article {
  grid-template-columns: 1fr;
  gap: calc(60 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .l-separate.is-article {
    grid-template-columns: 1fr 28.58%;
    gap: 5.36%;
  }
}
.l-separate_left, .l-separate_right {
  min-width: 0;
}

/******************************************************************************
* anime
*******************************************************************************/
.anime-scale .a-image, .anime-scale.a-image {
  overflow: hidden;
}
.anime-scale .a-image img, .anime-scale.a-image img {
  transition: scale var(--anime-scale-duration, 0.3s) var(--anime-scale-function, ease-out) var(--anime-scale-delay, 0s);
}
@media (any-hover: hover) {
  .anime-scale .a-image:hover:hover .a-image img, .anime-scale .a-image:hover:hover.a-image img, .anime-scale.a-image:hover:hover .a-image img, .anime-scale.a-image:hover:hover.a-image img {
    scale: 1.1;
  }
}
@media (any-hover: none) {
  .anime-scale .a-image:active:hover .a-image img, .anime-scale .a-image:active:hover.a-image img, .anime-scale.a-image:active:hover .a-image img, .anime-scale.a-image:active:hover.a-image img {
    scale: 1.1;
  }
}

/******************************************************************************
* anime-text-line
*******************************************************************************/
.anime-text-line {
  padding-bottom: calc(var(--anime-text-line-pb, 0) / 16 * 1rem);
  background-image: linear-gradient(to right, transparent 50%, var(--anime-text-line-color, currentColor) 50% 100%);
  background-repeat: repeat-x;
  background-position: -100% bottom;
  background-size: 200% calc(var(--anime-text-line-size, 1) / 16 * 1rem);
  transition: background-position var(--anime-text-line-duration, 0.3s) var(--anime-text-line-function, ease-out) var(--anime-text-line, 0s);
}
@media (any-hover: hover) {
  .anime-text-line:hover {
    background-position: -0.01% bottom;
    animation: line-gradation var(--anime-text-line-duration, 0.3s) var(--anime-text-line-function, ease-out) var(--anime-text-line, 0s);
  }
}
@media (any-hover: none) {
  .anime-text-line:active {
    background-position: -0.01% bottom;
    animation: line-gradation var(--anime-text-line-duration, 0.3s) var(--anime-text-line-function, ease-out) var(--anime-text-line, 0s);
  }
}

.anime-text-line-reverse {
  padding-bottom: calc(var(--anime-text-line-pb, 0) / 16 * 1rem);
  background-image: linear-gradient(to right, currentcolor 50%, transparent 50% 100%);
  background-repeat: repeat-x;
  background-position: -100% bottom;
  background-size: 200% calc(var(--anime-text-line-size, 1) / 16 * 1rem);
  transition: background-position var(--anime-text-line-duration, 0.3s) var(--anime-text-line-function, ease-out) var(--anime-text-line, 0s);
}
@media (any-hover: hover) {
  .anime-text-line-reverse:hover {
    background-position: 0 bottom;
    animation: line-gradation var(--anime-text-line-duration, 0.3s) var(--anime-text-line-function, ease-out) var(--anime-text-line, 0s);
  }
}
@media (any-hover: none) {
  .anime-text-line-reverse:active {
    background-position: 0 bottom;
    animation: line-gradation var(--anime-text-line-duration, 0.3s) var(--anime-text-line-function, ease-out) var(--anime-text-line, 0s);
  }
}

.anime-text-line-parent .anime-text-line {
  pointer-events: none;
}
@media (any-hover: hover) {
  .anime-text-line-parent:hover .anime-text-line {
    background-position: -0.01% bottom;
    animation: line-gradation var(--anime-text-line-duration, 0.3s) var(--anime-text-line-function, ease-out) var(--anime-text-line, 0s);
  }
}
@media (any-hover: none) {
  .anime-text-line-parent:active .anime-text-line {
    background-position: -0.01% bottom;
    animation: line-gradation var(--anime-text-line-duration, 0.3s) var(--anime-text-line-function, ease-out) var(--anime-text-line, 0s);
  }
}

@keyframes line-gradation {
  0% {
    background-position: 100% bottom;
  }
  100% {
    background-position: 0 bottom;
  }
}
.js-joblist-wrapper {
  visibility: hidden;
  opacity: 0;
}

.js-joblist-anim {
  position: relative;
  opacity: 0;
  transition: opacity 2s;
  perspective: 3000px;
}
.js-joblist-anim .o-jobs-default-card,
.js-joblist-anim .o-jobs-default-card_ura {
  backface-visibility: hidden;
  transition: transform 1s;
}
.js-joblist-anim .o-jobs-default-card {
  transform: rotateY(-180deg);
}
.js-joblist-anim .o-jobs-default-card_ura {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.js-joblist-anim.is-active {
  opacity: 1;
}
.js-joblist-anim.is-active .o-jobs-default-card {
  transform: rotateY(0);
}
.js-joblist-anim.is-active .o-jobs-default-card_ura {
  transform: rotateY(-180deg);
}

/******************************************************************************
* text
*******************************************************************************/
.a-text em,
.a-text-note em {
  font-weight: 700;
}
.a-text em a,
.a-text-note em a {
  --anime-text-line-size: 2;
  --anime-text-line-pb: 2;
}
.a-text a[target=_blank],
.a-text-note a[target=_blank] {
  position: relative;
  margin-right: 1.5em;
}
.a-text a[target=_blank]::after,
.a-text-note a[target=_blank]::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: calc(16 / 16 * 1rem);
  height: calc(16 / 16 * 1rem);
  content: "";
  background: url("data:image/svg+xml;charset=utf8,%3Csvg%20id%3D%22ic-link%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cdefs%3E%3CclipPath%3E%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg%2030%22%20clip-path%3D%22url(%23clip-path)%22%3E%3Cpath%20d%3D%22M18%2C12H9V3h9ZM18%2C2H9A1%2C1%2C0%2C0%2C0%2C8%2C3v9a1%2C1%2C0%2C0%2C0%2C1%2C1h9a1%2C1%2C0%2C0%2C0%2C1-1V3a1%2C1%2C0%2C0%2C0-1-1%22%20transform%3D%22translate(-4%20-1)%22%20fill%3D%22%2300a3a0%22%2F%3E%3Cpath%20d%3D%22M10%2C21.02v1H3a1%2C1%2C0%2C0%2C1-1-1v-7H3v7Z%22%20transform%3D%22translate(-1%20-7.01)%22%20fill%3D%22%2300a3a0%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E") 0 0 no-repeat;
  background-size: 100% 100%;
  transform: translate(100%, -50%);
}

.a-text-note {
  display: flex;
  color: var(--color-text-gray);
  font-size: calc(12 / 16 * 1rem);
}
.a-text-note > * {
  flex: 1;
}
.a-text-note::before {
  content: attr(data-note-head) "";
}

/******************************************************************************
* text-link
*******************************************************************************/
.a-text-link {
  display: inline-block;
  color: currentcolor;
}
.a-text-link.is-arrow {
  display: flex;
  align-items: center;
}
@media (any-hover: hover) {
  .a-text-link:hover {
    color: var(--hover-text-color);
    text-decoration: none;
  }
}
@media (any-hover: none) {
  .a-text-link:active {
    color: var(--hover-text-color);
    text-decoration: none;
  }
}

/******************************************************************************
* text-caption
*******************************************************************************/
.a-text-caption {
  font-size: calc(12 / 16 * 1rem);
  line-height: 1.5;
  color: var(--color-gray-99);
}

/******************************************************************************
* a-heading
*******************************************************************************/
.a-heading {
  display: grid;
  row-gap: 0.5em;
  font-size: calc(15 / 16 * 1rem);
  color: var(--color-black);
  /* stylelint-disable-next-line media-query-no-invalid */
}
.a-heading > * {
  display: block;
}
@media (min-width: 768px) {
  .a-heading {
    font-size: calc(20 / 16 * 1rem);
  }
  .a-heading > * {
    font-weight: 700;
  }
}
.a-heading b {
  color: var(--color-primary);
}

/******************************************************************************
* button
*******************************************************************************/
.a-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: background-color var(--anime-duration) var(--anime-function), color var(--anime-duration) var(--anime-function), border-color var(--anime-duration) var(--anime-function);
  /* stylelint-disable-next-line media-query-no-invalid */
}
.a-button.is-left {
  justify-content: flex-start;
  text-align: left;
}
.a-button_inner svg {
  transition: transform var(--anime-duration) var(--anime-function), fill var(--anime-duration) var(--anime-function);
}
.a-button.is-content-fit {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .a-button.is-content-fit {
    width: fit-content;
    min-width: calc(250 / 16 * 1rem);
    height: auto;
    min-height: calc(56 / 16 * 1rem);
    padding: calc(10 / 16 * 1rem) calc(40 / 16 * 1rem);
  }
}
.a-button.is-arrow-fixed {
  padding: 0 calc(56 / 16 * 1rem);
}
.a-button.is-arrow-fixed .a-button_inner svg {
  position: absolute;
  top: 50%;
  right: calc(12 / 16 * 1rem);
  transition: transform var(--anime-duration) var(--anime-function);
  translate: 0 -50%;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .a-button.is-arrow-fixed .a-button_inner svg {
    right: calc(16 / 16 * 1rem);
  }
}
.a-button.is-arrow-fixed.is-sp-left {
  padding-left: calc(20 / 16 * 1rem);
}
@media (min-width: 768px) {
  .a-button.md\:is-left {
    justify-content: flex-start;
    text-align: left;
  }
}
.a-button.is-design-square {
  border-radius: calc(4 / 16 * 1rem);
}
.a-button.is-design-capsule {
  border-radius: calc(100 / 16 * 1rem);
}
.a-button.is-type-solid {
  color: var(--color-white);
  background-color: var(--color-button-solid);
  border: solid calc(1 / 16 * 1rem) var(--color-button-solid);
  box-shadow: 0 3px 0 var(--color-button-solid-dark);
  --change-color-text: var(--color-white);
  --change-color-fill: var(--color-white);
  --change-color-bg: var(--color-button-solid-hover);
  --change-color-border: var(--color-button-solid-hover);
}
.a-button.is-type-solid svg {
  fill: var(--color-white);
}
.a-button.is-type-gradient {
  color: var(--color-white);
  background-image: var(--color-button-gradient01);
  background-position: 0 0;
  background-size: 200% 100%;
  transition: background-size var(--anime-duration) var(--anime-function);
  --change-color-text: var(--color-white);
  --change-color-fill: var(--color-white);
}
.a-button.is-type-gradient svg {
  fill: var(--color-white);
}
@media (any-hover: hover) {
  .a-button.is-type-gradient:hover {
    background-size: 100% 100%;
  }
}
@media (any-hover: none) {
  .a-button.is-type-gradient:active {
    background-size: 100% 100%;
  }
}
.a-button.is-type-cv-primary {
  color: var(--color-black);
  background-color: var(--color-cv-primary);
  border: solid calc(1 / 16 * 1rem) var(--color-cv-primary);
  box-shadow: 0 3px 0 var(--color-cv-primary-dark);
  --change-color-text: var(--color-cv-primary-text-hover);
  --change-color-fill: var(--color-white);
  --change-color-bg: var(--color-cv-primary-hover);
  --change-color-border: var(--color-cv-primary-hover);
}
.a-button.is-type-cv-primary svg {
  fill: var(--color-white);
}
.a-button.is-type-cv-secondary {
  color: var(--color-white);
  background-color: var(--color-cv-secondary);
  border: solid calc(1 / 16 * 1rem) var(--color-cv-secondary);
  box-shadow: 0 3px 0 var(--color-cv-secondary-dark);
  --change-color-text: var(--color-white);
  --change-color-fill: var(--color-white);
  --change-color-bg: var(--color-cv-secondary-hover);
  --change-color-border: var(--color-cv-secondary-hover);
}
.a-button.is-type-cv-secondary svg {
  fill: var(--color-white);
}
@media (any-hover: hover) {
  .a-button:hover {
    color: var(--change-color-text);
    background-color: var(--change-color-bg);
    border-color: var(--change-color-border);
  }
  .a-button:hover svg {
    fill: var(--change-color-fill);
  }
}
@media (any-hover: none) {
  .a-button:active {
    color: var(--change-color-text);
    background-color: var(--change-color-bg);
    border-color: var(--change-color-border);
  }
  .a-button:active svg {
    fill: var(--change-color-fill);
  }
}

/******************************************************************************
* button
*******************************************************************************/
.a-button-text {
  display: flex;
  gap: calc(6 / 16 * 1rem);
  align-items: center;
  width: fit-content;
  letter-spacing: 0.04em;
}
.a-button-text span {
  text-decoration: underline;
}
.a-button-text svg {
  fill: var(--color-primary);
}
@media (any-hover: hover) {
  .a-button-text:hover span {
    color: var(--hover-text-color);
    text-decoration: none;
  }
  .a-button-text:hover svg {
    fill: var(--color-button-solid-hover);
  }
}
@media (any-hover: none) {
  .a-button-text:active span {
    color: var(--hover-text-color);
    text-decoration: none;
  }
  .a-button-text:active svg {
    fill: var(--color-button-solid-hover);
  }
}

/******************************************************************************
* tag
*******************************************************************************/
.a-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: calc(6 / 16 * 1rem) calc(10 / 16 * 1rem);
  font-size: calc(12 / 16 * 1rem);
  line-height: 1.1;
  background-color: var(--color-primary-bg);
  border-radius: calc(5 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .a-tag {
    font-size: calc(14 / 16 * 1rem);
  }
}

a.a-tag {
  transition: background-color var(--anime-duration) var(--anime-function);
}
@media (any-hover: hover) {
  a.a-tag:hover {
    background-color: var(--color-gray-f5);
  }
}
@media (any-hover: none) {
  a.a-tag:active {
    background-color: var(--color-gray-f5);
  }
}

/******************************************************************************
* image
*******************************************************************************/
.a-image {
  position: relative;
  line-height: 0;
}
.a-image.is-fit {
  position: absolute;
  inset: 0;
}
.a-image.is-fit img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.a-image.is-round-5 {
  z-index: 1;
  overflow: hidden;
  border-radius: calc(5 / 16 * 1rem);
}
.a-image.is-round-10 {
  z-index: 1;
  overflow: hidden;
  border-radius: calc(10 / 16 * 1rem);
}

/******************************************************************************
* checkbox
*******************************************************************************/
.a-field-checkbox {
  position: relative;
  line-height: 1.3;
}
.a-field-checkbox input {
  display: none;
}
.a-field-checkbox input:checked + label::after {
  visibility: visible;
  opacity: 1;
}
.a-field-checkbox label {
  position: relative;
  display: block;
  padding-left: 1.6em;
  cursor: pointer;
}
.a-field-checkbox label::before, .a-field-checkbox label::after {
  position: absolute;
  top: 0.05em;
  left: 0;
  box-sizing: border-box;
  display: block;
  width: 1.2em;
  height: 1.2em;
  content: "";
  border-radius: calc(3 / 16 * 1rem);
}
.a-field-checkbox label::before {
  z-index: 1;
  background-color: var(--color-white);
  border: solid 1px var(--color-gray-99);
}
.a-field-checkbox label::after {
  z-index: 2;
  background-color: var(--color-primary);
  background-image: url("/assets/img/common/icon/icon_check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.9em auto;
  opacity: 0;
}

/* molecules */
.o-footer {
  position: relative;
}
.o-footer_bottom {
  position: relative;
  padding: calc(40 / 16 * 1rem) 0 calc(10 / 16 * 1rem);
  overflow: hidden;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer_bottom {
    width: 100%;
    padding: calc(120 / 16 * 1rem) 0 calc(44 / 16 * 1rem);
  }
}
.o-footer_bottom-logo {
  width: calc(142 / 16 * 1rem);
  margin: 0 auto calc(48 / 16 * 1rem);
  text-align: center;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer_bottom-logo {
    width: calc(280 / 16 * 1rem);
    margin: 0 auto calc(99 / 16 * 1rem);
  }
}
.o-footer_bottom-copyright {
  font-size: calc(10 / 16 * 1rem);
  line-height: calc(14 / 16 * 1rem);
  color: #000;
  text-align: center;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer_bottom-copyright {
    font-size: calc(12 / 16 * 1rem);
    line-height: calc(20 / 16 * 1rem);
  }
}
.o-footer_bottom-image {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: calc(955 / 16 * 1rem);
  margin: auto;
}
.o-footer_bottom-image picture:nth-child(1) {
  position: absolute;
  bottom: calc(-13 / 16 * 1rem);
  left: calc(-15 / 16 * 1rem);
  width: calc(98 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer_bottom-image picture:nth-child(1) {
    bottom: 0;
    left: calc(30 / 16 * 1rem);
    width: calc(249 / 16 * 1rem);
  }
}
.o-footer_bottom-image picture:nth-child(2) {
  position: absolute;
  right: calc(-12 / 16 * 1rem);
  bottom: 0;
  width: calc(84 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer_bottom-image picture:nth-child(2) {
    right: calc(20 / 16 * 1rem);
    width: calc(229 / 16 * 1rem);
  }
}

.o-footer-sitemap {
  position: relative;
  padding: calc(48 / 16 * 1rem) calc(20 / 16 * 1rem);
  background-color: var(--color-bg-dark);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-sitemap {
    padding: calc(60 / 16 * 1rem) calc(20 / 16 * 1rem);
  }
}
.o-footer-sitemap_inner {
  width: 100%;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-sitemap_inner {
    max-width: calc(1120 / 16 * 1rem);
    margin: 0 auto;
  }
}
.o-footer-sitemap_over {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(27 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-sitemap_over {
    gap: calc(20 / 16 * 1rem);
  }
}
.o-footer-sitemap_list {
  display: flex;
  flex-wrap: wrap;
  gap: calc(20 / 16 * 1rem) 0;
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-sitemap_list {
    gap: calc(20 / 16 * 1rem) calc(30 / 16 * 1rem);
  }
}
@media (max-width: 767px) {
  .o-footer-sitemap_list li {
    width: 50%;
  }
  .o-footer-sitemap_list:not(:first-child) {
    padding-top: calc(27 / 16 * 1rem);
    border-top: solid 1px var(--color-gray-70);
  }
}
.o-footer-sitemap_link {
  font-size: calc(14 / 16 * 1rem);
  color: var(--color-white);
  text-decoration: underline;
}
@media (any-hover: hover) {
  .o-footer-sitemap_link:hover {
    text-decoration: none;
  }
}
@media (any-hover: none) {
  .o-footer-sitemap_link:active {
    text-decoration: none;
  }
}
.o-footer-sitemap_under {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  margin-top: calc(27 / 16 * 1rem);
  color: var(--color-white);
  border-top: solid 1px var(--color-gray-70);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-sitemap_under {
    gap: calc(48 / 16 * 1rem);
    padding-top: calc(30 / 16 * 1rem);
    margin-top: calc(30 / 16 * 1rem);
  }
}
.o-footer-sitemap_under-item a {
  color: var(--color-white);
  text-decoration: underline;
}
@media (any-hover: hover) {
  .o-footer-sitemap_under-item a:hover {
    text-decoration: none;
  }
}
@media (any-hover: none) {
  .o-footer-sitemap_under-item a:active {
    text-decoration: none;
  }
}
.o-footer-sitemap_under-item.is-opened .o-footer-sitemap_under-head-arrow {
  transform: rotate(180deg);
}
.o-footer-sitemap_under-head {
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-sitemap_under-head {
    font-size: calc(18 / 16 * 1rem);
    font-weight: 700;
  }
}
@media (max-width: 767px) {
  .o-footer-sitemap_under-head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: calc(20 / 16 * 1rem) calc(40 / 16 * 1rem) calc(20 / 16 * 1rem) 0;
    font-size: calc(18 / 16 * 1rem);
    font-weight: 700;
    border-bottom: solid 1px var(--color-gray-70);
  }
}
.o-footer-sitemap_under-head-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: calc(20 / 16 * 1rem);
  height: calc(20 / 16 * 1rem);
  margin-top: calc(-10 / 16 * 1rem);
  line-height: 0;
  /* stylelint-disable-next-line media-query-no-invalid */
}
.o-footer-sitemap_under-head-arrow img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .o-footer-sitemap_under-head-arrow {
    display: none;
  }
}
.o-footer-sitemap_under-content {
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-sitemap_under-content {
    margin-top: calc(20 / 16 * 1rem);
  }
}
@media (max-width: 767px) {
  .o-footer-sitemap_under-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms linear;
  }
}
.o-footer-sitemap_under-content-inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(24 / 16 * 1rem);
  padding: calc(20 / 16 * 1rem) 0;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-sitemap_under-content-inner {
    gap: calc(24 / 16 * 1rem);
    padding: 0;
  }
}

.o-footer-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-list {
    display: flex;
    gap: calc(20 / 16 * 1rem);
    align-items: baseline;
  }
}
.o-footer-list dt {
  font-size: calc(14 / 16 * 1rem);
  font-weight: 700;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-list dt {
    flex-shrink: 0;
    font-size: calc(14 / 16 * 1rem);
  }
}
.o-footer-list dd {
  font-size: calc(12 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-list dd {
    flex: 1;
    font-size: calc(12 / 16 * 1rem);
  }
}
.o-footer-list_child {
  display: flex;
  flex-wrap: wrap;
  gap: calc(5 / 16 * 1rem) calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-list_child {
    gap: calc(5 / 16 * 1rem) calc(20 / 16 * 1rem);
  }
}

.o-footer-small-list {
  display: flex;
  flex-wrap: wrap;
  gap: calc(20 / 16 * 1rem);
  font-size: calc(12 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-small-list {
    gap: calc(24 / 16 * 1rem) calc(20 / 16 * 1rem);
    font-size: calc(12 / 16 * 1rem);
  }
}

.o-footer-simple {
  padding-block: calc(50 / 16 * 1rem);
  background: #262626;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-footer-simple {
    padding-block: calc(25 / 16 * 1rem);
  }
}
.o-footer-simple__copyright {
  font-size: calc(12 / 16 * 1rem);
  line-height: 1.6667;
  color: var(--color-white);
  text-align: center;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-footer-simple__copyright {
    font-size: calc(10 / 16 * 1rem);
    line-height: 1.4;
  }
}
.o-footer-simple__copyright br {
  display: none;
}
.o-footer-simple__copyright small {
  font-size: inherit;
}

.o-register {
  position: relative;
  padding: calc(40 / 16 * 1rem) 0 calc(165 / 16 * 1rem);
  background: var(--color-primary) url("/assets/img/common/register_bg_sp.webp") no-repeat center bottom;
  background-size: contain;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-register {
    padding: calc(40 / 16 * 1rem) 0;
    background: var(--color-primary) url("/assets/img/common/register_bg.webp") no-repeat center bottom;
    background-size: unset;
  }
}
.o-register_button {
  width: calc(300 / 16 * 1rem);
  height: calc(60 / 16 * 1rem);
  margin: calc(14 / 16 * 1rem) auto 0;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-register_button {
    width: calc(300 / 16 * 1rem);
    height: calc(62 / 16 * 1rem);
  }
}

.o-fixed-nav {
  display: none;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-fixed-nav {
    position: fixed;
    top: 50%;
    right: 0;
    z-index: 20;
    display: block;
    transform: translateY(-50%);
  }
}
.p-jobs-detail-page .o-fixed-nav {
  display: none;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .p-jobs-detail-page .o-fixed-nav {
    display: none;
  }
}
.o-fixed-nav_inner {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-fixed-nav_inner {
    display: grid;
    grid-template-columns: 1fr;
    gap: calc(6 / 16 * 1rem);
  }
}
.o-fixed-nav_link {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-fixed-nav_link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(90 / 16 * 1rem);
    height: calc(80 / 16 * 1rem);
    font-size: calc(12 / 16 * 1rem);
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    background-color: var(--color-white);
    border-top: solid 1px var(--color-white);
    border-bottom: solid 1px var(--color-white);
    border-left: solid 1px var(--color-white);
    border-radius: calc(5 / 16 * 1rem) 0 0 calc(5 / 16 * 1rem);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  .o-fixed-nav_link.is-secondary {
    color: var(--color-white);
    background-color: var(--color-cv-secondary);
  }
  .o-fixed-nav_link.is-secondary .o-fixed-nav_link-inner span {
    margin-top: calc(3 / 16 * 1rem);
  }
}
@media (min-width: 768px) and (any-hover: hover) {
  .o-fixed-nav_link.is-secondary:hover {
    background-color: var(--color-cv-secondary-hover);
    opacity: 1;
  }
}
@media (min-width: 768px) and (any-hover: none) {
  .o-fixed-nav_link.is-secondary:active {
    background-color: var(--color-cv-secondary-hover);
    opacity: 1;
  }
}
@media (min-width: 768px) and (any-hover: hover) {
  .o-fixed-nav_link:hover {
    background-color: var(--color-white);
  }
  .o-fixed-nav_link:hover .o-fixed-nav_link-inner {
    opacity: 0.8;
  }
}
@media (min-width: 768px) and (any-hover: none) {
  .o-fixed-nav_link:active {
    background-color: var(--color-white);
  }
  .o-fixed-nav_link:active .o-fixed-nav_link-inner {
    opacity: 0.8;
  }
}
.o-fixed-nav_link-inner span {
  display: block;
  margin-top: calc(10 / 16 * 1rem);
}

/* stylelint-disable no-duplicate-selectors */
.o-header {
  position: relative;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: auto;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header {
    position: relative;
    min-width: calc(1120 / 16 * 1rem);
    transition: transform 300ms ease-out;
  }
}
.o-header.is-dropdown-open {
  z-index: 999 !important;
}
.o-header_sub {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(20 / 16 * 1rem);
  padding: 0 calc(20 / 16 * 1rem);
  background-color: var(--color-primary);
  opacity: 1;
  transition: height 300ms linear, opacity 300ms linear;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_sub {
    position: relative;
    z-index: 10;
    height: calc(20 / 16 * 1rem);
    padding: 0 calc(40 / 16 * 1rem);
  }
}
.o-header_sub-inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_sub-inner {
    max-width: calc(1120 / 16 * 1rem);
  }
}
.o-header_sub-message-copy {
  font-size: calc(10 / 16 * 1rem);
  font-weight: 400;
  color: var(--color-white);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_sub-message-copy {
    font-size: calc(10 / 16 * 1rem);
    transform: translateY(-1px);
  }
}
.o-header_pc {
  display: none;
  width: 100%;
  height: auto;
  padding: 0 45px;
  margin: 0 auto;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_pc {
    display: block;
    padding: 0 calc(40 / 16 * 1rem);
    background-color: var(--color-white);
    border-bottom: solid 1px var(--color-gray-e5);
  }
}
.o-header_sp {
  right: 0;
  left: 0;
  display: block;
  background-color: var(--color-white);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_sp {
    display: none;
    width: 100%;
    height: auto;
  }
  .o-header_sp .o-header_menu button {
    background-color: var(--color-white);
    border-radius: 50%;
    transition: box-shadow 300ms ease-out;
  }
}
.o-header_inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc(54 / 16 * 1rem);
  margin: 0 auto;
  border-bottom: solid 1px var(--color-gray-e5);
  transition: height 300ms ease-out, box-shadow 300ms ease-out;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_inner {
    width: calc(1120 / 16 * 1rem);
    height: calc(85 / 16 * 1rem);
    border-bottom: none;
  }
}
.o-header_global {
  display: flex;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_global > div:not(:first-child) {
    margin-left: calc(30 / 16 * 1rem);
  }
}
.o-header_logo {
  display: flex;
  align-items: center;
  width: auto;
  line-height: 0;
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-header_logo {
    width: calc(117 / 16 * 1rem);
    margin-left: calc(20 / 16 * 1rem);
  }
}
@media (min-width: 768px) {
  .o-header_logo svg {
    width: 100%;
    height: auto;
  }
}
.o-header_logo-link {
  display: block;
}
.o-header_nav {
  margin-left: calc(30 / 16 * 1rem);
  font-size: calc(14 / 16 * 1rem);
}
.o-header_actions {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
.o-header_tel:not(:first-child) {
  margin-left: calc(10 / 16 * 1rem);
}
.o-header_tel-link {
  display: block;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_tel-link {
    pointer-events: none;
  }
}
.o-header_tel-num {
  display: flex;
  align-items: center;
  padding-top: calc(6 / 16 * 1rem);
  font-size: calc(22 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
}
.o-header_tel-num svg {
  fill: currentcolor;
}
.o-header_tel-num span {
  margin-left: calc(5 / 16 * 1rem);
}
.o-header_tel-time {
  font-size: calc(11 / 16 * 1rem);
  letter-spacing: normal;
}
.o-header_cv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* stylelint-disable-next-line media-query-no-invalid */
}
.o-header_cv:not(:first-child) {
  margin-left: calc(10 / 16 * 1rem);
}
@media (min-width: 768px) {
  .o-header_cv {
    display: flex;
  }
}
.o-header_cv-item {
  width: calc(124 / 16 * 1rem);
  height: calc(38 / 16 * 1rem);
  font-size: calc(13 / 16 * 1rem);
  font-weight: 700;
  letter-spacing: normal;
  transition: height 300ms ease-out;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_cv-item {
    width: calc(220 / 16 * 1rem);
    height: calc(50 / 16 * 1rem);
    font-size: calc(18 / 16 * 1rem);
  }
}
.o-header_cv-item:not(:first-of-type) {
  margin-left: 5px;
}
.o-header_cv-item .a-button {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_cv-item .a-button {
    justify-content: flex-end;
    padding: 0 calc(10 / 16 * 1rem);
  }
}
.o-header_cv-item .a-button_inner {
  display: flex;
  gap: calc(14 / 16 * 1rem);
  align-items: center;
}
.o-header_sp-cv {
  margin: 0 calc(10 / 16 * 1rem) 0 calc(4 / 16 * 1rem);
}
.o-header_sp-cv-item {
  width: calc(124 / 16 * 1rem);
  height: calc(38 / 16 * 1rem);
  font-size: calc(13 / 16 * 1rem);
  font-weight: 700;
  letter-spacing: normal;
  transition: height 300ms ease-out;
}
.o-header_sp-tel-item {
  width: calc(38 / 16 * 1rem);
  height: calc(38 / 16 * 1rem);
  transition: height 300ms ease-out;
}
.o-header_sp-tel-modal {
  padding: calc(30 / 16 * 1rem) calc(20 / 16 * 1rem);
  margin: calc(-8 / 16 * 1rem) 0 calc(-18 / 16 * 1rem) 0;
  background-color: var(--color-white);
  border-radius: calc(5 / 16 * 1rem);
}
.o-header_sp-tel-modal-wrap {
  display: flex !important;
  align-items: center;
}
.o-header_sp-tel-modal-title {
  display: flex;
  gap: calc(10 / 16 * 1rem);
  align-items: center;
  justify-content: center;
  font-size: calc(20 / 16 * 1rem);
  font-weight: 700;
}
.o-header_sp-tel-modal-copy {
  font-weight: 700;
  text-align: center;
}
.o-header_sp-tel-modal-button {
  width: 100%;
  height: calc(60 / 16 * 1rem);
  margin-top: calc(20 / 16 * 1rem);
  font-size: calc(18 / 16 * 1rem);
}
.o-header_menu {
  display: flex;
  align-items: center;
  height: 100%;
  border-left: solid 1px var(--color-gray-dd);
}
.o-header_menu .js-menu-trigger {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: calc(6 / 16 * 1rem);
  align-items: center;
  justify-content: center;
  width: calc(60 / 16 * 1rem);
  height: 100%;
  padding: 0;
  padding-top: calc(5 / 16 * 1rem);
  appearance: none;
  cursor: pointer;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_menu .js-menu-trigger {
    width: 8vw;
    height: 8vw;
  }
}
.o-header_menu span {
  pointer-events: none;
}
.o-header_menu-line {
  position: relative;
  display: block;
  width: calc(16 / 16 * 1rem);
  height: 3px;
  background-color: var(--color-primary);
  transition: all 0 ease-out 0s;
}
.o-header_menu-line::after {
  position: absolute;
  top: -6px;
  right: 0;
  left: 0;
  height: 3px;
  content: "";
  background-color: var(--color-primary);
  border-radius: 10px;
  transition: all 0 ease-out 0s;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_menu-line::after {
    top: -4px;
    height: 2px;
  }
}
.o-header_menu-line::before {
  position: absolute;
  right: 0;
  bottom: -6px;
  left: 0;
  height: 3px;
  content: "";
  background-color: var(--color-primary);
  border-radius: 10px;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-header_menu-line::before {
    bottom: -4px;
    height: 2px;
  }
}
.o-header_menu-name {
  font-size: calc(10 / 16 * 1rem);
  text-align: center;
  white-space: nowrap;
}

.is-humberger-open .o-header {
  z-index: 999 !important;
}
.is-humberger-open .o-header .o-header_sp .o-header_menu .o-header_menu-line {
  background-color: transparent;
}
.is-humberger-open .o-header .o-header_sp .o-header_menu .o-header_menu-line::before {
  bottom: 0;
  transform: rotate(-45deg);
}
.is-humberger-open .o-header .o-header_sp .o-header_menu .o-header_menu-line::after {
  top: 0;
  transform: rotate(45deg);
}
.is-humberger-open .o-header .o-header_sp .o-header_menu .o-header_menu-name {
  display: none;
}

.o-header {
  /* stylelint-disable-next-line selector-class-pattern */
}
.o-header.--simple .o-header_information {
  font-size: calc(20 / 16 * 1rem);
  padding-inline: calc(10 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.5;
  color: var(--color-primary);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-header.--simple .o-header_information {
    font-size: calc(14 / 16 * 1rem);
    padding-left: 0;
  }
}
.o-header.--simple .o-header_actions {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-header.--simple .o-header_actions {
    margin-inline: calc(10 / 16 * 1rem);
  }
}

.o-global-menu {
  display: none;
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-global-menu {
    display: flex;
    margin-left: calc(40 / 16 * 1rem);
    font-size: calc(14 / 16 * 1rem);
  }
}
@media (min-width: 1281px) {
  .o-global-menu {
    font-size: calc(15 / 16 * 1rem);
  }
}
.o-global-menu_item {
  position: relative;
  height: 100%;
  transition: opacity 200ms ease-out;
}
.o-global-menu_item:not(:first-of-type) {
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-global-menu_item:not(:first-of-type) {
    margin-left: calc(25 / 16 * 1rem);
  }
}
@media (min-width: 1281px) {
  .o-global-menu_item:not(:first-of-type) {
    margin-left: calc(30 / 16 * 1rem);
  }
}
.o-global-menu_panel {
  position: absolute;
  top: 80%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms ease-out, visibility 200ms ease-out, transform 200ms ease-out;
  transform: translateY(-5px);
}
.o-global-menu_panel.is-open {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.o-global-menu_panel-list {
  min-width: calc(400 / 16 * 1rem);
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}
.o-global-menu_panel-list-item:not(:first-of-type) {
  border-top: dotted 1px var(--color-gray-70);
}
.o-global-menu_panel-list-item .a-text-link {
  justify-content: space-between;
  width: 100%;
  padding: calc(18 / 16 * 1rem) calc(12 / 16 * 1rem) calc(18 / 16 * 1rem) calc(30 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.2;
}
.o-global-menu_trigger, .o-global-menu_link {
  display: flex;
  align-items: center;
  height: 100%;
}
.o-global-menu_trigger .a-text-link, .o-global-menu_link .a-text-link {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 700;
}
.o-global-menu_trigger.is-open .a-icon {
  transform: rotate(180deg);
}
.o-global-menu_trigger.is-open .is-touch-hover .a-icon {
  transform: rotate(180deg) translateX(0);
}

.o-hamburger-menu {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  height: auto;
  visibility: hidden;
  background-color: var(--color-white);
  opacity: 0;
  transition: opacity 200ms ease-out, visibility 200ms ease-out, transform 200ms ease-out;
}
.o-hamburger-menu.is-pc {
  display: none;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-hamburger-menu.is-pc {
    display: block;
  }
}
.o-hamburger-menu.is-sp {
  display: block;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-hamburger-menu.is-sp {
    display: none;
  }
}
.o-hamburger-menu_inner {
  max-height: calc(100vh - (54 / 16 * 1rem));
  padding-bottom: calc(130 / 16 * 1rem);
  overflow: auto;
}

.o-hamburger-menu-sitemap {
  padding: 0 calc(20 / 16 * 1rem);
  margin-top: calc(22 / 16 * 1rem);
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
}
.o-hamburger-menu-sitemap_cols {
  border-bottom: solid 1px var(--color-gray-70);
}
.o-hamburger-menu-sitemap_rows {
  border-top: solid 1px var(--color-gray-70);
}
.o-hamburger-menu-sitemap_rows > .a-text-link span {
  padding-left: calc(8 / 16 * 1rem);
  border-left: solid 3px var(--color-primary);
}
.o-hamburger-menu-sitemap_rows.is-opened > .a-text-link svg {
  transform: rotate(180deg);
}
.o-hamburger-menu-sitemap_child {
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-out;
}
.o-hamburger-menu-sitemap_child-list {
  padding: 0 calc(20 / 16 * 1rem);
  margin-top: calc(-18 / 16 * 1rem);
}
.o-hamburger-menu-sitemap_child-list li:not(:first-child) {
  border-top: dotted 1px var(--color-gray-70);
}
.o-hamburger-menu-sitemap .a-text-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(18 / 16 * 1rem) 0;
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-black);
}
.o-hamburger-menu-sitemap .a-text-link span {
  flex: 1;
  padding-right: 1.5em;
}
.o-hamburger-menu-sitemap .a-text-link svg {
  flex-shrink: 0;
  width: calc(20 / 16 * 1rem);
  height: calc(20 / 16 * 1rem);
}

.o-hamburger-menu-cv {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(10 / 16 * 1rem);
  padding: 0 calc(20 / 16 * 1rem);
  margin-top: calc(34 / 16 * 1rem);
}
.o-hamburger-menu-cv_item {
  width: 100%;
  height: calc(68 / 16 * 1rem);
}
.o-hamburger-menu-cv_item .a-button.is-arrow-fixed {
  padding: 0 calc(20 / 16 * 1rem);
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
}
.o-hamburger-menu-cv_item .o-hamburger-menu-cv_tel {
  flex-direction: column;
  gap: calc(3 / 16 * 1rem);
  align-items: center;
  line-height: 1.2;
  color: var(--color-black);
  background-color: var(--color-gray-f5);
  border: solid calc(1 / 16 * 1rem) var(--color-gray-f5);
  box-shadow: 0 3px 0 var(--color-gray-af);
  --change-color-text: var(--color-black);
  --change-color-bg: var(--color-gray-f5);
  --change-color-border: var(--color-gray-f5);
}
.o-hamburger-menu-cv_item .o-hamburger-menu-cv_tel svg {
  fill: var(--color-black);
}
.o-hamburger-menu-cv_item .o-hamburger-menu-cv_tel-over {
  display: flex;
  gap: calc(8 / 16 * 1rem);
  align-items: center;
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
}
.o-hamburger-menu-cv_item .o-hamburger-menu-cv_tel-under {
  display: flex;
  gap: calc(8 / 16 * 1rem);
  align-items: center;
}
.o-hamburger-menu-cv_item .o-hamburger-menu-cv_tel-under b {
  font-size: calc(16 / 16 * 1rem);
}
.o-hamburger-menu-cv_item .o-hamburger-menu-cv_tel-under small {
  font-size: calc(10 / 16 * 1rem);
}

.o-hamburger-menu-ui {
  display: flex;
  gap: calc(10 / 16 * 1rem);
  align-items: center;
  justify-content: center;
  padding: 0 calc(40 / 16 * 1rem);
  margin-top: calc(20 / 16 * 1rem);
}
.o-hamburger-menu-ui_item {
  width: 100%;
  height: calc(64 / 16 * 1rem);
}
.o-hamburger-menu-ui_button {
  flex-direction: column;
  gap: calc(3 / 16 * 1rem);
  align-items: center;
  font-size: calc(16 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.2;
  color: var(--color-black);
  background-color: var(--color-white);
  border: solid calc(1 / 16 * 1rem) var(--color-gray-cc);
  border-radius: calc(5 / 16 * 1rem);
  box-shadow: 0 3px 0 var(--color-gray-cc);
}
.o-hamburger-menu-ui_button .a-image {
  width: calc(20 / 16 * 1rem);
  height: calc(20 / 16 * 1rem);
}
.o-hamburger-menu-ui_button .a-image img {
  width: 100%;
  height: auto;
}

.o-hamburger-menu-search {
  padding: calc(20 / 16 * 1rem) calc(40 / 16 * 1rem);
  background-color: var(--color-primary);
}

.is-humberger-open .o-hamburger-menu {
  visibility: visible;
  opacity: 1;
}

.o-breadcrumb {
  position: relative;
}
.o-breadcrumb_list {
  display: flex;
  flex-wrap: wrap;
  row-gap: calc(8 / 16 * 1rem);
  height: auto;
  padding: 0;
  font-size: calc(12 / 16 * 1rem); /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-breadcrumb_list {
    padding: calc(18 / 16 * 1rem) 0;
    font-size: calc(12 / 16 * 1rem);
  }
}
.o-breadcrumb_list li {
  display: flex;
  align-items: center;
  line-height: 1.3;
}
.o-breadcrumb_list li:not(:last-child)::after {
  display: block;
  margin: 0 calc(10 / 16 * 1rem);
  content: ">";
}
.o-breadcrumb_list li:last-child a {
  text-decoration: none;
  pointer-events: none;
}
.o-breadcrumb_link {
  color: var(--color-black);
  text-decoration: underline;
}
@media (any-hover: hover) {
  .o-breadcrumb_link:hover {
    text-decoration: none;
  }
}
@media (any-hover: none) {
  .o-breadcrumb_link:active {
    text-decoration: none;
  }
}

.o-footer-banner-wrap {
  padding-bottom: calc(40 / 16 * 1rem);
  margin-top: calc(40 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-footer-banner-wrap {
    padding-bottom: calc(60 / 16 * 1rem);
    margin-top: calc(100 / 16 * 1rem);
  }
}

.o-footer-banner {
  position: relative;
}
.o-footer-banner_link {
  display: block;
}
@media (any-hover: hover) {
  .o-footer-banner_link:hover {
    opacity: var(--hover-image-opacity);
  }
}
@media (any-hover: none) {
  .o-footer-banner_link:active {
    opacity: var(--hover-image-opacity);
  }
}

/******************************************************************************
* o-adviser-banner
*******************************************************************************/
.o-adviser-banner {
  position: relative;
  width: 100%;
  height: auto;
  padding: calc(24 / 16 * 1rem) calc(20 / 16 * 1rem) calc(20 / 16 * 1rem);
  color: var(--color-white);
  background-color: var(--color-primary);
  border-radius: calc(5 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-adviser-banner {
    height: calc(330 / 16 * 1rem);
    padding: calc(30 / 16 * 1rem) calc(40 / 16 * 1rem) calc(46 / 16 * 1rem);
  }
}
.o-adviser-banner_inner {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: calc(900 / 16 * 1rem);
  margin: 0 auto;
}
.o-adviser-banner_heading {
  color: var(--color-white);
}
.o-adviser-banner_heading b {
  color: var(--color-white);
  background-image: linear-gradient(transparent 70%, var(--color-secondary) 70%);
}
.o-adviser-banner_text {
  margin-top: calc(14 / 16 * 1rem);
  font-size: calc(14 / 16 * 1rem);
  font-weight: 700;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-adviser-banner_text {
    margin-top: calc(20 / 16 * 1rem);
    font-size: calc(16 / 16 * 1rem);
    font-weight: 400;
  }
}
.o-adviser-banner_button {
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-adviser-banner_button {
    margin-top: calc(30 / 16 * 1rem);
  }
}
@media (max-width: 767px) {
  .o-adviser-banner_button {
    margin-top: calc(132 / 16 * 1rem);
  }
}
.o-adviser-banner_button-item {
  width: 100%;
  height: calc(68 / 16 * 1rem);
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-adviser-banner_button-item {
    width: calc(380 / 16 * 1rem);
    height: calc(80 / 16 * 1rem);
    font-size: calc(23 / 16 * 1rem);
  }
}
.o-adviser-banner_button-item .a-button.is-arrow-fixed {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-adviser-banner_button-item .a-button.is-arrow-fixed {
    justify-content: flex-start;
    padding: 0 calc(30 / 16 * 1rem);
  }
}
.o-adviser-banner_image {
  position: absolute;
  right: calc(34 / 16 * 1rem);
  bottom: calc(52 / 16 * 1rem);
  z-index: 1;
  width: calc(260 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-adviser-banner_image {
    right: calc(120 / 16 * 1rem);
    bottom: 0;
    width: calc(425 / 16 * 1rem);
  }
}

/******************************************************************************
* o-sp-search
*******************************************************************************/
.o-sp-search_text {
  position: relative;
  margin-top: calc(8 / 16 * 1rem);
}
.o-sp-search_text-inner {
  position: relative;
}
.o-sp-search_text-inner svg {
  position: absolute;
  top: 50%;
  left: calc(14 / 16 * 1rem);
  width: calc(20 / 16 * 1rem);
  height: calc(20 / 16 * 1rem);
  fill: var(--color-gray-99);
  transform: translateY(-50%);
}
.o-sp-search_text-input {
  box-sizing: border-box;
  width: 100%;
  height: calc(48 / 16 * 1rem);
  padding: calc(12 / 16 * 1rem) calc(12 / 16 * 1rem) calc(12 / 16 * 1rem) calc(40 / 16 * 1rem);
  font-size: calc(16 / 16 * 1rem);
  appearance: none;
  border: none;
  border-radius: calc(5 / 16 * 1rem);
  outline: none;
}
.o-sp-search_text-input ::placeholder {
  font-size: calc(16 / 16 * 1rem);
  color: var(--color-gray-99);
}
.o-sp-search_select {
  padding: calc(5 / 16 * 1rem) calc(20 / 16 * 1rem);
  background-color: var(--color-white);
  border-radius: calc(5 / 16 * 1rem);
}
.o-sp-search_select-button {
  min-width: 0;
}
.o-sp-search_select-button:not(:first-of-type) {
  border-top: dotted 1px var(--color-gray-99);
}
.o-sp-search_select-button-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(40 / 16 * 1rem);
  font-size: calc(14 / 16 * 1rem);
  font-weight: 700;
  background-color: var(--color-white);
}
.o-sp-search_select-button-item i {
  display: block;
  flex-shrink: 0;
  width: calc(15 / 16 * 1rem);
  height: calc(15 / 16 * 1rem);
  line-height: 0;
}
.o-sp-search_select-button-item i svg {
  width: 100%;
  height: 100%;
}
.o-sp-search_select-button-item span {
  max-width: 100%;
  padding-right: calc(10 / 16 * 1rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.o-sp-search_submit {
  position: relative;
  margin-top: calc(8 / 16 * 1rem);
}
.o-sp-search_submit-button {
  width: 100%;
  height: calc(48 / 16 * 1rem);
  font-size: calc(16 / 16 * 1rem);
  color: var(--color-white);
  background-color: var(--color-black);
  border-radius: calc(5 / 16 * 1rem);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

/******************************************************************************
* faq
*******************************************************************************/
.o-faq {
  position: relative;
}
.o-faq.is-opened dt::after {
  transform: rotate(180deg);
}
.o-faq h2,
.o-faq h3,
.o-faq h4 {
  position: relative;
  padding: calc(20 / 16 * 1rem) calc(30 / 16 * 1rem) calc(20 / 16 * 1rem) calc(45 / 16 * 1rem);
  font-size: calc(20 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.4;
  color: var(--color-primary);
  cursor: pointer;
  border-bottom: solid 1px var(--color-gray-cc);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-faq h2,
  .o-faq h3,
  .o-faq h4 {
    padding: calc(35 / 16 * 1rem) calc(50 / 16 * 1rem) calc(35 / 16 * 1rem) calc(75 / 16 * 1rem);
    font-size: calc(24 / 16 * 1rem);
  }
}
.o-faq h2::before,
.o-faq h3::before,
.o-faq h4::before {
  position: absolute;
  top: calc(16 / 16 * 1rem);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(34 / 16 * 1rem);
  height: calc(34 / 16 * 1rem);
  font-size: calc(20 / 16 * 1rem);
  font-weight: 700;
  line-height: calc(34 / 16 * 1rem);
  color: var(--color-white);
  content: "";
  background-color: var(--color-primary);
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22.24" height="30.56" viewBox="0 0 22.24 30.56"><path d="M8.42,24.38C3.36,23.42,0,18.94,0,12.19,0,4.48,4.32,0,10.59,0s10.59,4.51,10.59,12.19c0,6.46-3.07,10.81-7.78,12.03,1.09,1.79,3.23,2.53,5.38,2.53,1.02,0,1.92-.16,2.59-.38l.86,3.49c-.83,.38-2.27,.7-4,.7-4.83,0-8.19-2.59-9.82-6.18Zm7.94-12.19c0-5.09-2.24-8.13-5.76-8.13s-5.76,3.04-5.76,8.13,2.24,8.51,5.76,8.51,5.76-3.2,5.76-8.51Z" style="fill:%23fff;"/></svg>');
  background-repeat: no-repeat;
  background-position: calc(10 / 16 * 1rem) calc(8 / 16 * 1rem);
  background-size: calc(15 / 16 * 1rem) auto;
  border-radius: 50%;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-faq h2::before,
  .o-faq h3::before,
  .o-faq h4::before {
    top: calc(29 / 16 * 1rem);
    width: calc(54 / 16 * 1rem);
    height: calc(54 / 16 * 1rem);
    font-size: calc(32 / 16 * 1rem);
    line-height: calc(54 / 16 * 1rem);
    background-position: calc(16 / 16 * 1rem) calc(14 / 16 * 1rem);
    background-size: calc(23 / 16 * 1rem) auto;
  }
}
.o-faq h2::after,
.o-faq h3::after,
.o-faq h4::after {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: calc(20 / 16 * 1rem);
  height: calc(20 / 16 * 1rem);
  margin-top: calc(-10 / 16 * 1rem);
  content: "";
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g><path d="M3,5.5c.26,0,.51,.1,.71,.29l6.29,6.29,6.29-6.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41l-7,7c-.39,.39-1.02,.39-1.41,0L2.29,7.21c-.39-.39-.39-1.02,0-1.41,.2-.2,.45-.29,.71-.29Z" style="fill:%2361b7d9;"/></g><rect width="20" height="20" style="fill:none;"/></svg>') no-repeat center center;
  background-size: contain;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-faq h2::after,
  .o-faq h3::after,
  .o-faq h4::after {
    width: calc(20 / 16 * 1rem);
    height: calc(20 / 16 * 1rem);
    margin-top: calc(-10 / 16 * 1rem);
  }
}
@media (any-hover: hover) {
  .o-faq h2:hover,
  .o-faq h3:hover,
  .o-faq h4:hover {
    opacity: 0.8;
  }
}
@media (any-hover: none) {
  .o-faq h2:active,
  .o-faq h3:active,
  .o-faq h4:active {
    opacity: 0.8;
  }
}
.o-faq > div {
  max-height: 0;
  overflow: hidden;
  transition: max-height 200ms ease-in-out;
}
.o-faq_content {
  padding-top: calc(20 / 16 * 1rem);
}
.o-faq_content-inner {
  position: relative;
  padding: calc(30 / 16 * 1rem) calc(20 / 16 * 1rem) calc(30 / 16 * 1rem) calc(60 / 16 * 1rem);
  background-color: var(--color-bg03);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-faq_content-inner {
    padding: calc(40 / 16 * 1rem) calc(20 / 16 * 1rem) calc(30 / 16 * 1rem) calc(75 / 16 * 1rem);
  }
}
.o-faq_content-inner::before {
  position: absolute;
  left: calc(20 / 16 * 1rem);
  font-size: calc(24 / 16 * 1rem);
  font-weight: 700;
  line-height: 1;
  color: var(--color-primary);
  content: "A：";
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-faq_content-inner::before {
    left: calc(20 / 16 * 1rem);
    font-size: calc(32 / 16 * 1rem);
  }
}
.o-faq_text {
  font-size: calc(16 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-faq_text {
    font-size: calc(18 / 16 * 1rem);
  }
}
.o-faq_text .a-text-link {
  color: var(--color-primary);
  text-decoration: underline;
}
@media (any-hover: hover) {
  .o-faq_text .a-text-link:hover {
    text-decoration: none;
  }
}
@media (any-hover: none) {
  .o-faq_text .a-text-link:active {
    text-decoration: none;
  }
}

/******************************************************************************
* form-submit-loading
*******************************************************************************/
.o-form-submit-loading {
  display: none;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.o-form-submit-loading:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: var(--color-black);
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.o-form-submit-loading__content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.o-form-submit-loading__inner {
  font-size: 0;
  text-align: center;
}
.o-form-submit-loading__icon {
  display: block;
  width: 60px;
  margin-bottom: 20px;
}
.o-form-submit-loading__icon svg {
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
}
.o-form-submit-loading__text {
  display: block;
  font-size: calc(20 / 16 * 1rem);
  line-height: 1.2;
  letter-spacing: 0.075em;
  color: var(--color-white);
}

/******************************************************************************
* bullet-list
*******************************************************************************/
ul.o-bullet-list > li .a-text,
ul.o-bullet-list > li .a-text-link,
ul.o-bullet-list .o-bullet-list_item .a-text,
ul.o-bullet-list .o-bullet-list_item .a-text-link,
dl.o-bullet-list > li .a-text,
dl.o-bullet-list > li .a-text-link,
dl.o-bullet-list .o-bullet-list_item .a-text,
dl.o-bullet-list .o-bullet-list_item .a-text-link {
  display: flex;
}
ul.o-bullet-list > li .a-text > *:last-child,
ul.o-bullet-list > li .a-text-link > *:last-child,
ul.o-bullet-list .o-bullet-list_item .a-text > *:last-child,
ul.o-bullet-list .o-bullet-list_item .a-text-link > *:last-child,
dl.o-bullet-list > li .a-text > *:last-child,
dl.o-bullet-list > li .a-text-link > *:last-child,
dl.o-bullet-list .o-bullet-list_item .a-text > *:last-child,
dl.o-bullet-list .o-bullet-list_item .a-text-link > *:last-child {
  flex: 1;
}
ul.o-bullet-list > li .a-text::before,
ul.o-bullet-list > li .a-text-link::before,
ul.o-bullet-list .o-bullet-list_item .a-text::before,
ul.o-bullet-list .o-bullet-list_item .a-text-link::before,
dl.o-bullet-list > li .a-text::before,
dl.o-bullet-list > li .a-text-link::before,
dl.o-bullet-list .o-bullet-list_item .a-text::before,
dl.o-bullet-list .o-bullet-list_item .a-text-link::before {
  --bullet-size: 6;
  display: block;
  width: calc(var(--bullet-size) / 16 * 1rem);
  min-width: calc(var(--bullet-size) / 16 * 1rem);
  height: calc(var(--bullet-size) / 16 * 1rem);
  min-height: calc(var(--bullet-size) / 16 * 1rem);
  max-height: calc(var(--bullet-size) / 16 * 1rem);
  margin-right: calc(10 / 16 * 1rem);
  content: "";
  background-color: var(--color-primary);
  border-radius: 9999px;
  transform: translateY(calc(var(--line-height-base) * 0.5em - (var(--bullet-size) / 16 * 1rem) / 2));
}
ul.o-bullet-list .o-bullet-list,
dl.o-bullet-list .o-bullet-list {
  padding-left: 1.3em;
}

/******************************************************************************
* order-list
*******************************************************************************/
ol.o-order-list,
dl.o-order-list {
  counter-reset: number;
}
ol.o-order-list > li .a-text,
ol.o-order-list .o-bullet-list_item .a-text,
dl.o-order-list > li .a-text,
dl.o-order-list .o-bullet-list_item .a-text {
  display: flex;
  counter-increment: number;
}
ol.o-order-list > li .a-text > *:last-child,
ol.o-order-list .o-bullet-list_item .a-text > *:last-child,
dl.o-order-list > li .a-text > *:last-child,
dl.o-order-list .o-bullet-list_item .a-text > *:last-child {
  flex: 1;
}
ol.o-order-list > li .a-text::before,
ol.o-order-list .o-bullet-list_item .a-text::before,
dl.o-order-list > li .a-text::before,
dl.o-order-list .o-bullet-list_item .a-text::before {
  margin-right: 0.5em;
  font-weight: 700;
  color: var(--color-primary);
  content: counter(number) ".";
}
ol.o-order-list .o-order-list,
dl.o-order-list .o-order-list {
  padding-left: 1.3em;
  margin-top: calc(30 / 16 * 1rem);
}

/******************************************************************************
* text-list
*******************************************************************************/
ul.o-text-list > li .a-text,
ul.o-text-list > li .a-text-link,
ul.o-text-list .o-bullet-list_item .a-text,
ul.o-text-list .o-bullet-list_item .a-text-link,
dl.o-order-list > li .a-text,
dl.o-order-list > li .a-text-link,
dl.o-order-list .o-bullet-list_item .a-text,
dl.o-order-list .o-bullet-list_item .a-text-link {
  display: flex;
  align-items: center;
}
ul.o-text-list > li .a-text > *:last-child,
ul.o-text-list > li .a-text-link > *:last-child,
ul.o-text-list .o-bullet-list_item .a-text > *:last-child,
ul.o-text-list .o-bullet-list_item .a-text-link > *:last-child,
dl.o-order-list > li .a-text > *:last-child,
dl.o-order-list > li .a-text-link > *:last-child,
dl.o-order-list .o-bullet-list_item .a-text > *:last-child,
dl.o-order-list .o-bullet-list_item .a-text-link > *:last-child {
  flex: 1;
}
ul.o-text-list > li .a-text::before,
ul.o-text-list > li .a-text-link::before,
ul.o-text-list .o-bullet-list_item .a-text::before,
ul.o-text-list .o-bullet-list_item .a-text-link::before,
dl.o-order-list > li .a-text::before,
dl.o-order-list > li .a-text-link::before,
dl.o-order-list .o-bullet-list_item .a-text::before,
dl.o-order-list .o-bullet-list_item .a-text-link::before {
  content: attr(data-list-style);
}
ul.o-text-list .o-text-list,
dl.o-order-list .o-text-list {
  padding-left: 1.3em;
}

/******************************************************************************
* media-card
*******************************************************************************/
.o-media-card {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(20 / 16 * 1rem);
  min-width: 0;
}
.o-media-card_content-inner {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(15 / 16 * 1rem);
}

/******************************************************************************
* o-article-index-card
*******************************************************************************/
.o-article-index-card {
  position: relative;
  min-width: 0;
}
.o-article-index-card_content {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(10 / 16 * 1rem);
  margin-top: calc(15 / 16 * 1rem);
}
.o-article-index-card_link::before {
  position: absolute;
  inset: 0;
  z-index: 5;
  display: block;
  content: "";
}
@media (any-hover: hover) {
  .o-article-index-card_link:hover .o-article-index-card_visual {
    opacity: var(--hover-image-opacity);
  }
  .o-article-index-card_link:hover + .o-article-index-card_content .o-article-index-card_date,
  .o-article-index-card_link:hover + .o-article-index-card_content .o-article-index-card_heading,
  .o-article-index-card_link:hover + .o-article-index-card_content .o-article-index-card_text {
    color: var(--hover-text-color);
  }
  .o-article-index-card_link:hover + .o-article-index-card_content .o-article-index-card_tag {
    opacity: var(--hover-image-opacity);
  }
}
@media (any-hover: none) {
  .o-article-index-card_link:active .o-article-index-card_visual {
    opacity: var(--hover-image-opacity);
  }
  .o-article-index-card_link:active + .o-article-index-card_content .o-article-index-card_date,
  .o-article-index-card_link:active + .o-article-index-card_content .o-article-index-card_heading,
  .o-article-index-card_link:active + .o-article-index-card_content .o-article-index-card_text {
    color: var(--hover-text-color);
  }
  .o-article-index-card_link:active + .o-article-index-card_content .o-article-index-card_tag {
    opacity: var(--hover-image-opacity);
  }
}
.o-article-index-card_visual {
  position: relative;
  width: 100%;
  padding-top: 47.7777777778%;
}
.o-article-index-card_visual .a-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
.o-article-index-card_visual .a-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}
.o-article-index-card_date {
  display: flex;
  align-items: center;
  font-size: calc(14 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-article-index-card_date {
    font-size: calc(14 / 16 * 1rem);
  }
}
.o-article-index-card_date p:not(:first-child)::before {
  display: inline-block;
  margin-left: calc(5 / 16 * 1rem);
  color: var(--color-gray-cc);
  content: "｜";
}
.o-article-index-card_text p {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.o-article-index-card_tag {
  position: relative;
  z-index: 10;
}

/******************************************************************************
* o-article-side
*******************************************************************************/
.o-article-side {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(60 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-article-side {
    grid-template-columns: 1fr;
    gap: calc(40 / 16 * 1rem);
  }
}
.o-article-side_item {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(20 / 16 * 1rem);
  min-width: 0;
}

.o-article-side-index {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(18 / 16 * 1rem);
}
.o-article-side-index li {
  min-width: 0;
}
.o-article-side-index_link {
  display: flex;
  gap: calc(15 / 16 * 1rem);
  align-items: center;
}
@media (any-hover: hover) {
  .o-article-side-index_link:hover {
    opacity: var(--hover-image-opacity);
  }
}
@media (any-hover: none) {
  .o-article-side-index_link:active {
    opacity: var(--hover-image-opacity);
  }
}
.o-article-side-index_visual {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  width: calc(90 / 16 * 1rem);
  height: calc(60 / 16 * 1rem);
}
.o-article-side-index_visual img {
  width: 100%;
  height: auto;
}
.o-article-side-index_content {
  flex: 1;
}
.o-article-side-index_text {
  display: -webkit-box;
  overflow: hidden;
  font-size: calc(14 / 16 * 1rem);
  line-height: 1.4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.o-article-side-category {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(4 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-article-side-category {
    gap: calc(2 / 16 * 1rem);
  }
}
.o-article-side-category li {
  min-width: 0;
}
.o-article-side-category_link {
  display: flex;
  gap: calc(15 / 16 * 1rem);
  align-items: center;
  justify-content: space-between;
  height: calc(48 / 16 * 1rem);
  padding: 0 calc(15 / 16 * 1rem);
  font-size: calc(16 / 16 * 1rem);
  background-color: var(--color-bg);
  border-radius: calc(5 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-article-side-category_link {
    font-size: calc(14 / 16 * 1rem);
  }
}
.o-article-side-category_link svg {
  flex-shrink: 0;
  width: calc(16 / 16 * 1rem);
  fill: var(--color-primary);
}
@media (any-hover: hover) {
  .o-article-side-category_link:hover {
    opacity: 0.7;
  }
}
@media (any-hover: none) {
  .o-article-side-category_link:active {
    opacity: 0.7;
  }
}

.o-article-side-banner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(20 / 16 * 1rem);
}
.o-article-side-banner_item {
  min-width: 0;
}
.o-article-side-banner_link {
  display: block;
}
@media (any-hover: hover) {
  .o-article-side-banner_link:hover {
    opacity: var(--hover-image-opacity);
  }
}
@media (any-hover: none) {
  .o-article-side-banner_link:active {
    opacity: var(--hover-image-opacity);
  }
}
.o-article-side-banner_link img {
  width: 100%;
  height: auto;
}

/******************************************************************************
* o-single-button
*******************************************************************************/
.o-single-button {
  width: calc(310 / 16 * 1rem);
  height: calc(60 / 16 * 1rem);
  margin: 0 auto;
  font-size: calc(16 / 16 * 1rem);
  font-weight: 700; /* stylelint-disable-next-line media-query-no-invalid */
}
.o-single-button.is-sp-wide {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-single-button.is-sp-wide {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .o-single-button {
    width: fit-content;
    height: calc(62 / 16 * 1rem);
    font-size: calc(18 / 16 * 1rem);
  }
  .o-single-button .a-button {
    min-width: calc(300 / 16 * 1rem);
  }
}

/* =========================================================
o-modal
========================================================= */
.o-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  display: block;
  width: 100%;
  height: 100vh;
  padding: calc(30 / 16 * 1rem) calc(20 / 16 * 1rem) calc(100 / 16 * 1rem);
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  transition: visibility 400ms ease-out, opacity 400ms ease-out;
}
.o-modal.is-active {
  visibility: visible;
  opacity: 1;
}
.o-modal_bg {
  position: fixed;
  inset: 0;
  z-index: 1;
  touch-action: none;
  background-color: rgba(0, 0, 0, 0.7);
}
.o-modal_wrap {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 800px;
  padding: calc(20 / 16 * 1rem) calc(10 / 16 * 1rem) calc(30 / 16 * 1rem);
  background-color: var(--color-primary-bg);
  border-radius: calc(5 / 16 * 1rem);
}
.o-modal_inner {
  width: 100%;
}
.o-modal_close {
  position: absolute;
  top: calc(-15 / 16 * 1rem);
  right: calc(-15 / 16 * 1rem);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(48 / 16 * 1rem);
  height: calc(48 / 16 * 1rem);
  cursor: pointer;
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: background-color 300ms ease-out;
}
.o-modal_close::before, .o-modal_close::after {
  display: block;
  width: 59%;
  height: 1px;
  content: "";
  background-color: var(--color-black);
}
.o-modal_close::before {
  transform: rotate(45deg);
}
.o-modal_close::after {
  margin-top: -1px;
  transform: rotate(-45deg);
}
.o-modal_content-head {
  padding: 0 calc(10 / 16 * 1rem);
}
.o-modal_content-body {
  padding: calc(10 / 16 * 1rem) calc(20 / 16 * 1rem);
  margin: calc(18 / 16 * 1rem) 0 0;
  background-color: var(--color-white);
}
.o-modal_content-foot {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(15 / 16 * 1rem);
  padding: 0 calc(20 / 16 * 1rem);
  margin-top: calc(30 / 16 * 1rem);
}
.o-modal_select {
  width: 100%;
  min-width: 0;
  height: calc(60 / 16 * 1rem);
}
.o-modal_select .a-button {
  font-size: calc(18 / 16 * 1rem);
}
.o-modal_clear {
  display: flex;
  justify-content: center;
}
.o-modal_clear-button {
  display: flex;
  gap: calc(10 / 16 * 1rem);
  align-items: center;
  justify-content: center;
  font-size: calc(16 / 16 * 1rem);
}

.o-malti-anchor.is-link-only {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(6 / 16 * 1rem) calc(5 / 16 * 1rem);
  padding: calc(10 / 16 * 1rem) 0;
}
.o-malti-anchor_item-first {
  padding: calc(18 / 16 * 1rem) 0;
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
}
.o-malti-anchor_item-first:not(:last-child) {
  border-bottom: solid 1px var(--color-gray-70);
}
.o-malti-anchor_item-second {
  padding: calc(18 / 16 * 1rem) 0;
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
  border-top: dotted 1px var(--color-gray-70);
}
.o-malti-anchor_item-second:first-of-type {
  margin-top: calc(18 / 16 * 1rem);
}
.o-malti-anchor_item-second:last-child {
  padding-bottom: 0;
}
.o-malti-anchor_item-second.is-link {
  padding: 0;
  margin-top: calc(5 / 16 * 1rem);
  border-top: none;
}
.o-malti-anchor_item-second.is-link:first-of-type {
  margin-top: calc(18 / 16 * 1rem);
}
.o-malti-anchor_item-third {
  font-size: calc(15 / 16 * 1rem);
  font-weight: 400;
}
.o-malti-anchor_item-third:first-of-type {
  margin-top: calc(18 / 16 * 1rem);
}
.o-malti-anchor_item-third:not(:first-child) {
  margin-top: calc(5 / 16 * 1rem);
}
.o-malti-anchor_item-link {
  justify-content: space-between;
}
.o-malti-anchor_item-link svg {
  width: calc(24 / 16 * 1rem);
  height: calc(24 / 16 * 1rem);
  margin-right: calc(-8 / 16 * 1rem);
}
.o-malti-anchor_item-child-link {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
  padding: calc(8 / 16 * 1rem);
  font-size: calc(15 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.3;
  background-color: var(--color-primary-light);
  border-radius: calc(5 / 16 * 1rem);
}
.o-malti-anchor_title strong {
  font-weight: 700;
}
.o-malti-anchor_acd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.o-malti-anchor_acd i {
  flex-shrink: 0;
  line-height: 0;
}
.o-malti-anchor_acd.is-opened i svg {
  transform: rotate(180deg);
}
.o-malti-anchor_acd-content {
  display: none;
  padding: 0 calc(10 / 16 * 1rem);
}
.o-malti-anchor_acd-content.is-second.is-link-only {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(5 / 16 * 1rem);
  padding: 0;
}
.o-malti-anchor_acd-content.is-third {
  display: none;
  padding: 0;
}

/* =========================================================
o-modal
========================================================= */
.o-modal-search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: block;
  width: 100%;
  height: 100vh;
  padding: calc(30 / 16 * 1rem) calc(20 / 16 * 1rem) calc(100 / 16 * 1rem);
  overflow: auto;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: visibility 400ms ease-out, opacity 400ms ease-out;
}
.o-modal-search.is-active {
  visibility: visible;
  opacity: 1;
}
.o-modal-search_bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  touch-action: none;
}
.o-modal-search_wrap {
  position: relative;
  z-index: 100;
  width: 100%;
  max-width: 800px;
  padding: calc(20 / 16 * 1rem) calc(10 / 16 * 1rem) calc(30 / 16 * 1rem);
  background-color: var(--color-primary-bg);
  border-radius: calc(5 / 16 * 1rem);
}
.o-modal-search_inner {
  width: 100%;
}
.o-modal-search_close {
  position: absolute;
  top: calc(-15 / 16 * 1rem);
  right: calc(-15 / 16 * 1rem);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(48 / 16 * 1rem);
  height: calc(48 / 16 * 1rem);
  cursor: pointer;
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: background-color 300ms ease-out;
}
.o-modal-search_close::before, .o-modal-search_close::after {
  display: block;
  width: 59%;
  height: 1px;
  content: "";
  background-color: var(--color-black);
}
.o-modal-search_close::before {
  transform: rotate(45deg);
}
.o-modal-search_close::after {
  margin-top: -1px;
  transform: rotate(-45deg);
}
.o-modal-search_content-head {
  padding: 0 calc(10 / 16 * 1rem);
}
.o-modal-search_content-body {
  padding: calc(10 / 16 * 1rem) calc(20 / 16 * 1rem);
  margin: calc(18 / 16 * 1rem) 0 0;
  background-color: var(--color-white);
}
.o-modal-search_content-foot {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(15 / 16 * 1rem);
  padding: 0 calc(20 / 16 * 1rem);
  margin-top: calc(30 / 16 * 1rem);
}
.o-modal-search_select {
  width: 100%;
  min-width: 0;
  height: calc(60 / 16 * 1rem);
}
.o-modal-search_select .a-button {
  font-size: calc(18 / 16 * 1rem);
}
.o-modal-search_clear {
  display: flex;
  justify-content: center;
}
.o-modal-search_clear-button {
  display: flex;
  gap: calc(10 / 16 * 1rem);
  align-items: center;
  justify-content: center;
  font-size: calc(16 / 16 * 1rem);
}

.o-malti-checkbox_item-first {
  padding: calc(20 / 16 * 1rem) 0;
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
}
.o-malti-checkbox_item-first:not(:last-child) {
  border-bottom: solid 1px var(--color-gray-70);
}
.o-malti-checkbox_item-second {
  padding: calc(20 / 16 * 1rem) 0;
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
  border-top: dotted 1px var(--color-gray-70);
}
.o-malti-checkbox_item-second:first-of-type {
  margin-top: calc(20 / 16 * 1rem);
}
.o-malti-checkbox_item-second:last-child {
  padding-bottom: 0;
}
.o-malti-checkbox_item-third {
  padding: calc(10 / 16 * 1rem) 0;
  font-size: calc(15 / 16 * 1rem);
  font-weight: 400;
  border-top: dotted 1px var(--color-gray-70);
}
.o-malti-checkbox_item-third:first-of-type {
  margin-top: calc(20 / 16 * 1rem);
}
.o-malti-checkbox_item-third:last-child {
  padding-bottom: 0;
}
.o-malti-checkbox_acd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.o-malti-checkbox_acd i {
  flex-shrink: 0;
  line-height: 0;
}
.o-malti-checkbox_acd i.is-opened svg {
  transform: rotate(180deg);
}
.o-malti-checkbox_acd-content {
  display: none;
  padding: 0 calc(10 / 16 * 1rem);
}

/******************************************************************************
* o-dialog-modal
*******************************************************************************/
.o-dialog-modal {
  display: none;
  width: 100%;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  user-select: none;
}
.o-dialog-modal__wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  padding: calc(85 / 16 * 1rem) calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-dialog-modal__wrapper {
    padding: calc(40 / 16 * 1rem) calc(20 / 16 * 1rem);
  }
}
.o-dialog-modal__content {
  margin-inline: auto;
  position: relative;
}
.o-dialog-modal__inner {
  margin-inline: auto;
}

.o-dialog-modal-favorite {
  display: block;
  width: calc(400 / 16 * 1rem);
  padding: calc(30 / 16 * 1rem) calc(30 / 16 * 1rem) calc(35 / 16 * 1rem);
  background: var(--color-white);
  filter: drop-shadow(0 calc(3 / 16 * 1rem) calc(20 / 16 * 1rem) rgba(0, 0, 0, 0.32));
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-dialog-modal-favorite {
    width: calc(300 / 16 * 1rem);
  }
}
.o-dialog-modal-favorite__inner {
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
  line-height: 2;
  text-align: center;
}
.o-dialog-modal-favorite__inner:before {
  content: "";
  display: block;
  width: calc(50 / 16 * 1rem);
  aspect-ratio: 1/1;
  margin-inline: auto;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path fill="%23e7f4f8" d="M25,0h0c13.81,0,25,11.19,25,25h0c0,13.81-11.19,25-25,25h0C11.19,50,0,38.81,0,25h0C0,11.19,11.19,0,25,0Z"/><path d="M35,19.58c0-2.53-2.05-4.58-4.58-4.58h-10.83c-2.53,0-4.58,2.05-4.58,4.58v.83c0,.76.3,1.49.83,2.04v7.96c0,2.53,2.05,4.58,4.58,4.58h9.17c2.53,0,4.58-2.05,4.58-4.58v-7.96c.53-.54.83-1.27.83-2.04v-.83ZM17.5,19.58c0-1.15.93-2.08,2.08-2.08h10.83c1.15,0,2.08.93,2.08,2.08h0v.83c0,.23-.19.42-.42.42h-14.17c-.23,0-.42-.19-.42-.42v-.83ZM31.67,30.42c0,1.15-.93,2.08-2.08,2.08h-9.17c-1.15,0-2.08-.93-2.08-2.08h0v-7.08h13.33v7.08h0Z"/><path fill="%23049cd8" d="M22.92,25h4.17c.69,0,1.25.56,1.25,1.25h0c0,.69-.56,1.25-1.25,1.25h-4.17c-.69,0-1.25-.56-1.25-1.25h0c0-.69.56-1.25,1.25-1.25Z"/></svg>');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: calc(15 / 16 * 1rem);
}

.o-dialog-modal-error {
  display: block;
  width: calc(400 / 16 * 1rem);
  padding: calc(30 / 16 * 1rem) calc(20 / 16 * 1rem) calc(35 / 16 * 1rem);
  background: var(--color-white);
  filter: drop-shadow(0 calc(3 / 16 * 1rem) calc(20 / 16 * 1rem) rgba(0, 0, 0, 0.32));
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-dialog-modal-error {
    width: calc(300 / 16 * 1rem);
  }
}
.o-dialog-modal-error__inner {
  font-size: calc(18 / 16 * 1rem);
  font-weight: 700;
  line-height: 2;
  text-align: center;
}
.o-dialog-modal-error__inner:before {
  content: "";
  display: block;
  width: calc(50 / 16 * 1rem);
  aspect-ratio: 1/1;
  margin-inline: auto;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill="%23ffe8e8"><path d="M21.03,2.46c1.1-2.19,3.76-3.08,5.95-1.99.86.43,1.56,1.13,1.99,1.99l20.56,41.12c1.1,2.19.21,4.86-1.99,5.96-.62.31-1.3.47-1.98.47H4.44C1.99,50,0,48.01,0,45.56c0-.69.16-1.37.47-1.98L21.03,2.46Z"/><path fill="red" d="M24.2,32.25l-.59-9.33-.14-3.92h4.14l-.14,3.92-.59,9.33h-2.69ZM25.55,40.7c-.7.02-1.37-.27-1.83-.78-.47-.52-.73-1.2-.71-1.9-.03-.71.23-1.41.71-1.93.47-.51,1.14-.78,1.83-.76.69-.02,1.36.25,1.83.76.48.52.74,1.22.71,1.93.02.7-.24,1.38-.71,1.9-.46.52-1.14.81-1.83.79Z"/></svg>');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: calc(15 / 16 * 1rem);
}

/******************************************************************************
* o-jobs-default-card
*******************************************************************************/
.o-jobs-default-card {
  border: 1px solid #049cd8;
  border-radius: calc(5 / 16 * 1rem);
  background: var(--color-white);
  filter: drop-shadow(calc(1 / 16 * 1rem) calc(2 / 16 * 1rem) 0 rgba(0, 0, 0, 0.46));
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.o-jobs-default-card__head {
  padding: calc(20 / 16 * 1rem) calc(20 / 16 * 1rem) 0;
  margin-bottom: calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__head {
    padding: calc(20 / 16 * 1rem) calc(15 / 16 * 1rem) 0;
    margin-bottom: calc(10 / 16 * 1rem);
  }
}
.--carousel-item .o-jobs-default-card__head {
  padding: calc(15 / 16 * 1rem) calc(15 / 16 * 1rem) 0;
  margin-bottom: calc(15 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__head {
    padding: calc(15 / 16 * 1rem) calc(15 / 16 * 1rem) 0;
    margin-bottom: calc(10 / 16 * 1rem);
  }
}
.o-jobs-default-card__body {
  padding: 0 calc(20 / 16 * 1rem) calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__body {
    padding: 0 calc(15 / 16 * 1rem) calc(20 / 16 * 1rem);
  }
}
.--carousel-item .o-jobs-default-card__body {
  padding: 0 calc(15 / 16 * 1rem) calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__body {
    padding: 0 calc(15 / 16 * 1rem) calc(20 / 16 * 1rem);
  }
}
.o-jobs-default-card__foot {
  border-top: 1px solid var(--color-gray-dd);
  padding: calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__foot {
    display: none;
    border-top: 0;
    padding: 0 calc(15 / 16 * 1rem) calc(20 / 16 * 1rem);
  }
}
.--history-item .o-jobs-default-card__foot, .--favorite-item .o-jobs-default-card__foot {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--history-item .o-jobs-default-card__foot, .--favorite-item .o-jobs-default-card__foot {
    display: block;
  }
}
.o-jobs-default-card.--new:before {
  content: "";
  display: block;
  width: calc(40 / 16 * 1rem);
  aspect-ratio: 1/1;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><path fill="%2361b7d9" d="M39.64.36l.36,39.64L0,0l39.64.36Z"/><path fill="%23fff" d="M36.59,20.29c-.11-.12-.26-.19-.41-.22-.14-.02-.3,0-.43.07l-3.73,2.08,1.96-3.86c.07-.13.1-.29.07-.44-.03-.16-.11-.3-.22-.4l-.28-.28c-.11-.12-.27-.2-.42-.22-.14-.02-.28,0-.41.06l-3.88,1.96,2.08-3.74c.08-.13.1-.29.08-.45-.03-.16-.11-.3-.22-.4l-4.6-4.6-5.52,5.52,3.62,3.62,1.13-1.13-2.29-2.29,1.1-1.1,1.8,1.8,1.1-1.1-1.8-1.8,1.07-1.07,2.29,2.29.89-.89-3.64,6.91,1.13,1.13,4.48-2.29-2.3,4.47,1.14,1.14,7.23-3.81-1-.99Z"/><path fill="%23fff" d="M24.28,7.98l-3.23,3.23.65-5.36c0-.06.01-.13,0-.19,0-.06-.01-.13-.04-.2-.02-.06-.05-.11-.1-.17-.04-.05-.08-.09-.12-.13l-.69-.69-5.52,5.52,1.21,1.21,3.23-3.23-.64,5.33c-.02.13-.01.27.03.41.05.12.12.23.21.31l.68.68,5.52-5.52-1.21-1.21Z"/></svg>');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: -1px;
  right: -1px;
  z-index: 2;
}
.o-jobs-default-card__date {
  font-size: calc(14 / 16 * 1rem);
  line-height: 1.42;
  margin-bottom: calc(5 / 16 * 1rem);
}
.o-jobs-default-card__title a {
  display: inline-block;
}
@media (any-hover: hover) {
  .o-jobs-default-card__title a:hover {
    text-decoration: underline;
  }
}
@media (any-hover: none) {
  .o-jobs-default-card__title a:active {
    text-decoration: underline;
  }
}
.o-jobs-default-card__title .a-heading_text-one {
  font-size: calc(24 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__title .a-heading_text-one {
    font-size: calc(20 / 16 * 1rem);
    line-height: 1.45;
  }
}
.--carousel-item .o-jobs-default-card__title {
  height: calc(60 / 16 * 1rem);
  overflow: hidden;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__title {
    height: calc(53 / 16 * 1rem);
  }
}
.--carousel-item .o-jobs-default-card__title .a-heading_text-one {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__title .a-heading_text-one {
    font-size: calc(18 / 16 * 1rem);
  }
}
.o-jobs-default-card__grid {
  display: grid;
  grid-template-columns: calc(260 / 16 * 1rem) 1fr;
  grid-template-rows: auto 1fr;
  gap: calc(15 / 16 * 1rem) calc(30 / 16 * 1rem);
  width: 100%;
  margin-bottom: calc(20 / 16 * 1rem);
  grid-template-areas: "image salary" "image data";
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__grid {
    grid-template-columns: calc(90 / 16 * 1rem) 1fr;
    gap: calc(15 / 16 * 1rem) calc(10 / 16 * 1rem);
    grid-template-areas: "salary salary" "image data";
    margin-bottom: calc(15 / 16 * 1rem);
  }
}
.--carousel-item .o-jobs-default-card__grid {
  grid-template-columns: calc(140 / 16 * 1rem) 1fr;
  grid-template-areas: "salary salary" "image data";
  gap: calc(15 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__grid {
    grid-template-columns: calc(90 / 16 * 1rem) 1fr;
    grid-template-areas: "salary salary" "image data";
    gap: calc(15 / 16 * 1rem) calc(10 / 16 * 1rem);
  }
}
.o-jobs-default-card__image {
  grid-area: image;
}
.o-jobs-default-card__image a {
  display: block;
}
@media (any-hover: hover) {
  .o-jobs-default-card__image a:hover {
    opacity: 0.7;
  }
}
@media (any-hover: none) {
  .o-jobs-default-card__image a:active {
    opacity: 0.7;
  }
}
.o-jobs-default-card__image .a-image {
  border-radius: calc(5 / 16 * 1rem);
  overflow: hidden;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__image .a-image {
    aspect-ratio: 1/1;
  }
}
.o-jobs-default-card__image .a-image picture, .o-jobs-default-card__image .a-image img {
  display: block;
  width: 100%;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__image .a-image picture, .o-jobs-default-card__image .a-image img {
    height: 100%;
  }
}
.o-jobs-default-card__image .a-image img {
  aspect-ratio: 260/172;
  object-fit: cover;
}
.o-jobs-default-card__salary {
  border-top: 1px solid var(--color-gray-70);
  border-bottom: 1px solid var(--color-gray-70);
  padding: calc(9 / 16 * 1rem) calc(10 / 16 * 1rem);
  font-size: calc(14 / 16 * 1rem);
  font-family: var(--font-family-en);
  line-height: 1.7;
  text-align: center;
  grid-area: salary;
}
.o-jobs-default-card__salary b {
  font-size: calc(30 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.5;
  color: #d30493;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__salary b {
    font-size: calc(24 / 16 * 1rem);
  }
}
.--carousel-item .o-jobs-default-card__salary {
  padding: calc(7 / 16 * 1rem) 0;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__salary {
    padding: calc(7 / 16 * 1rem) 0;
  }
}
.--carousel-item .o-jobs-default-card__salary b {
  font-size: calc(28 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__salary b {
    font-size: calc(20 / 16 * 1rem);
  }
}
.o-jobs-default-card__data-list {
  grid-area: data;
}
.o-jobs-default-card__data-list > ul {
  display: flex;
  flex-direction: column;
  gap: calc(15 / 16 * 1rem);
}
.--carousel-item .o-jobs-default-card__data-list > ul {
  gap: calc(15 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__data-list > ul {
    gap: calc(10 / 16 * 1rem);
  }
}
.o-jobs-default-card__data {
  font-size: calc(16 / 16 * 1rem);
}
.o-jobs-default-card__data dl {
  display: grid;
  grid-template-columns: calc(130 / 16 * 1rem) auto;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__data dl {
    display: block;
  }
}
.o-jobs-default-card__data dt {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__data dt {
    margin-bottom: calc(2 / 16 * 1rem);
  }
}
.o-jobs-default-card__data dd {
  line-height: 1.5;
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-jobs-default-card__data dd {
    position: relative;
    top: calc(3 / 16 * 1rem);
  }
}
@media (max-width: 767px) {
  .o-jobs-default-card__data dd {
    line-height: 1.375;
  }
}
.o-jobs-default-card__data a {
  display: inline;
}
.--carousel-item .o-jobs-default-card__data {
  font-size: calc(16 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__data {
    font-size: calc(14 / 16 * 1rem);
  }
}
.--carousel-item .o-jobs-default-card__data dd {
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 1.3;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__data dd {
    line-height: 1.375;
  }
}
.o-jobs-default-card__data a {
  text-decoration: underline;
}
@media (any-hover: hover) {
  .o-jobs-default-card__data a:hover {
    text-decoration: none;
  }
}
@media (any-hover: none) {
  .o-jobs-default-card__data a:active {
    text-decoration: none;
  }
}
.--carousel-item .o-jobs-default-card__data dl {
  display: block;
}
.o-jobs-default-card__label {
  font-size: calc(20 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.45;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__label {
    font-size: calc(16 / 16 * 1rem);
    line-height: 1.5;
  }
}
.--carousel-item .o-jobs-default-card__label {
  font-size: calc(16 / 16 * 1rem);
  line-height: 1.4;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__label {
    font-size: calc(14 / 16 * 1rem);
  }
}
.o-jobs-default-card__label[data-icon] {
  display: grid;
  grid-template-columns: calc(20 / 16 * 1rem) auto;
  gap: calc(10 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__label[data-icon] {
    grid-template-columns: calc(16 / 16 * 1rem) auto;
    gap: calc(5 / 16 * 1rem);
  }
}
.o-jobs-default-card__label[data-icon]:before {
  content: "";
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  margin-block: auto;
  background-position: center center;
  background-repeat: no-repeat;
}
.--carousel-item .o-jobs-default-card__label[data-icon] {
  grid-template-columns: calc(16 / 16 * 1rem) auto;
  gap: calc(5 / 16 * 1rem);
}
.o-jobs-default-card__label[data-icon=occupation]:before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 18.75" fill="%23049cd8"><path d="M7.19,1.87h5.63c.17,0,.31.14.31.31v1.56h-6.25v-1.56c0-.17.14-.31.31-.31ZM5,2.19v1.56h-2.5c-1.38,0-2.5,1.12-2.5,2.5v3.75h20v-3.75c0-1.38-1.12-2.5-2.5-2.5h-2.5v-1.56c0-1.21-.98-2.19-2.19-2.19h-5.63c-1.21,0-2.19.98-2.19,2.19h0ZM20,11.25h-7.5v1.25c0,.69-.56,1.25-1.25,1.25,0,0,0,0,0,0h-2.5c-.69,0-1.25-.56-1.25-1.25,0,0,0,0,0,0v-1.25H0v5c0,1.38,1.12,2.5,2.5,2.5h15c1.38,0,2.5-1.12,2.5-2.5v-5Z"/></svg>');
  background-size: calc(17.5 / 16 * 1rem) calc(16.41 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__label[data-icon=occupation]:before {
    background-size: calc(16 / 16 * 1rem) calc(16 / 16 * 1rem);
  }
}
.--carousel-item .o-jobs-default-card__label[data-icon=occupation]:before {
  background-size: calc(16 / 16 * 1rem) calc(16 / 16 * 1rem);
}
.o-jobs-default-card__label[data-icon=place]:before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.03 20" fill="%23049cd8"><path d="M8.45,19.54c2.01-2.51,6.59-8.6,6.59-12.03C15.03,3.36,11.67,0,7.51,0,3.36,0,0,3.37,0,7.52,0,10.94,4.58,17.03,6.59,19.55c.41.51,1.15.6,1.66.19.07-.06.14-.12.19-.19h0ZM7.52,10.03c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5,2.5,1.12,2.5,2.5c0,1.38-1.12,2.5-2.5,2.5Z"/></svg>');
  background-size: calc(15 / 16 * 1rem) calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__label[data-icon=place]:before {
    background-size: calc(12 / 16 * 1rem) calc(16 / 16 * 1rem);
  }
}
.--carousel-item .o-jobs-default-card__label[data-icon=place]:before {
  background-size: calc(12 / 16 * 1rem) calc(16 / 16 * 1rem);
}
.o-jobs-default-card__label[data-icon=desc]:before {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.46 19.27" fill="%23049cd8"><path d="M2.41,0C1.08,0,0,1.08,0,2.41v14.46c0,1.33,1.08,2.41,2.41,2.41h9.64c1.33,0,2.41-1.08,2.41-2.41V6.02h-4.82c-.66,0-1.2-.54-1.2-1.2V0H2.41ZM9.64,0v4.82h4.82L9.64,0ZM4.22,9.64h6.02c.33,0,.6.27.6.6s-.27.6-.6.6h-6.02c-.33,0-.6-.27-.6-.6,0-.33.27-.6.6-.6h0ZM4.22,12.05h6.02c.33,0,.6.27.6.6s-.27.6-.6.6h-6.02c-.33,0-.6-.27-.6-.6,0-.33.27-.6.6-.6h0ZM4.22,14.45h6.02c.33,0,.6.27.6.6s-.27.6-.6.6h-6.02c-.33,0-.6-.27-.6-.6,0-.33.27-.6.6-.6h0Z"/></svg>');
  background-size: calc(14.5 / 16 * 1rem) calc(19.3 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__label[data-icon=desc]:before {
    background-size: calc(12 / 16 * 1rem) calc(16 / 16 * 1rem);
  }
}
.--carousel-item .o-jobs-default-card__label[data-icon=desc]:before {
  background-size: calc(12 / 16 * 1rem) calc(16 / 16 * 1rem);
}
.o-jobs-default-card__desc__head {
  margin-bottom: calc(5 / 16 * 1rem);
}
.o-jobs-default-card__desc__body {
  position: relative;
  line-height: 1.375;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__desc__body {
    font-size: calc(14 / 16 * 1rem);
    line-height: 1.571;
  }
}
.o-jobs-default-card__desc__text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__desc__text {
    -webkit-line-clamp: 5;
  }
}
.--carousel-item .o-jobs-default-card__desc__text {
  height: calc(90 / 16 * 1rem);
  -webkit-line-clamp: 4;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--carousel-item .o-jobs-default-card__desc__text {
    -webkit-line-clamp: 4;
  }
}
[data-desc=open] .o-jobs-default-card__desc__text {
  display: block;
}
.o-jobs-default-card__desc__more {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
[data-desc=close] .o-jobs-default-card__desc__more {
  display: block;
}
.--carousel-item .o-jobs-default-card__desc__more {
  display: none !important;
}
.o-jobs-default-card__desc__more a {
  display: grid;
  grid-template-columns: auto calc(10 / 16 * 1rem);
  gap: calc(5 / 16 * 1rem);
  padding-inline: 0.5em;
  background: var(--color-white);
  font-size: calc(16 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.375;
  color: var(--color-primary);
  text-decoration: underline;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__desc__more a {
    font-size: calc(14 / 16 * 1rem);
    line-height: 1.571;
  }
}
.o-jobs-default-card__desc__more a:after {
  content: "";
  display: block;
  width: 100%;
  aspect-ratio: 10/6;
  margin-block: auto;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6.5" fill="%2361b7d9"><polygon points="10 1.4 8.63 0 5 3.71 1.37 0 0 1.4 4.99 6.5 5 6.49 5.01 6.5 10 1.4"/></svg>');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (any-hover: hover) {
  .o-jobs-default-card__desc__more a:hover {
    text-decoration: none;
  }
}
@media (any-hover: none) {
  .o-jobs-default-card__desc__more a:active {
    text-decoration: none;
  }
}
.o-jobs-default-card__tag-wrap {
  height: calc(36 / 16 * 1rem);
  overflow: hidden;
  margin-top: calc(20 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__tag-wrap {
    height: calc(30 / 16 * 1rem);
    margin-top: calc(15 / 16 * 1rem);
  }
}
.o-jobs-default-card__tag ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: calc(10 / 16 * 1rem);
  font-size: 0;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__tag ul {
    gap: calc(5 / 16 * 1rem);
  }
}
.o-jobs-default-card__tag ul > li {
  max-width: 100%;
  flex: 0 0 auto;
}
.--carousel-item .o-jobs-default-card__tag ul {
  gap: calc(5 / 16 * 1rem);
}
.o-jobs-default-card__tag-comment {
  display: inline-grid;
  align-items: center;
  max-width: 100%;
  height: calc(36 / 16 * 1rem);
  padding: calc(7 / 16 * 1rem);
  border-radius: calc(5 / 16 * 1rem);
  background: var(--color-primary-bg);
  font-size: calc(14 / 16 * 1rem);
  line-height: 1.428;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__tag-comment {
    height: calc(30 / 16 * 1rem);
    padding: calc(5 / 16 * 1rem) calc(8 / 16 * 1rem);
  }
}
.o-jobs-default-card__tag-item {
  display: inline-grid;
  align-items: center;
  max-width: 100%;
  height: calc(36 / 16 * 1rem);
  padding: calc(7 / 16 * 1rem);
  border: 1px solid var(--color-primary-bg);
  border-radius: calc(5 / 16 * 1rem);
  background: var(--color-primary-bg);
  font-size: calc(14 / 16 * 1rem);
  line-height: 1.428;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__tag-item {
    height: calc(30 / 16 * 1rem);
    padding: calc(5 / 16 * 1rem) calc(8 / 16 * 1rem);
  }
}
@media (any-hover: hover) {
  .o-jobs-default-card__tag-item:hover {
    border-color: #049cd8;
    color: #049cd8;
  }
}
@media (any-hover: none) {
  .o-jobs-default-card__tag-item:active {
    border-color: #049cd8;
    color: #049cd8;
  }
}
.o-jobs-default-card__comment__label {
  display: inline-grid;
  align-items: center;
  height: calc(36 / 16 * 1rem);
  padding: calc(8 / 16 * 1rem);
  border-radius: calc(5 / 16 * 1rem);
  background: var(--color-primary-bg);
  font-size: calc(14 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.428;
  color: var(--color-black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__comment__label {
    height: calc(30 / 16 * 1rem);
    padding: calc(5 / 16 * 1rem) calc(8 / 16 * 1rem);
  }
}
.o-jobs-default-card__link {
  font-size: 0;
  text-align: center;
}
.o-jobs-default-card__link > ul {
  display: inline-grid;
  grid-template-columns: repeat(3, calc(227 / 16 * 1rem));
  gap: calc(5 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-default-card__link > ul {
    display: grid;
    grid-template-columns: 1fr;
    gap: calc(10 / 16 * 1rem);
  }
}
.--history-item .o-jobs-default-card__link > ul, .--favorite-item .o-jobs-default-card__link > ul {
  grid-template-areas: "apply" "keep";
}
.--history-item .o-jobs-default-card__link > ul > li, .--favorite-item .o-jobs-default-card__link > ul > li {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .--history-item .o-jobs-default-card__link > ul > li:has([data-button=link]), .--favorite-item .o-jobs-default-card__link > ul > li:has([data-button=link]) {
    display: none;
  }
  .--history-item .o-jobs-default-card__link > ul > li:has([data-button=keep]), .--favorite-item .o-jobs-default-card__link > ul > li:has([data-button=keep]) {
    grid-area: keep;
  }
  .--history-item .o-jobs-default-card__link > ul > li:has([data-button=apply]), .--favorite-item .o-jobs-default-card__link > ul > li:has([data-button=apply]) {
    grid-area: apply;
  }
}
.o-jobs-default-card__link a {
  display: block;
  width: 100%;
  padding: calc(8 / 16 * 1rem) calc(20 / 16 * 1rem) calc(9 / 16 * 1rem);
  border: 1px solid var(--color-primary);
  border-radius: calc(5 / 16 * 1rem);
  font-size: calc(16 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.5;
  box-shadow: 0 calc(2 / 16 * 1rem) 0 #177ea7;
}
@media (any-hover: hover) {
  .o-jobs-default-card__link a:hover {
    opacity: var(--hover-image-opacity);
  }
}
@media (any-hover: none) {
  .o-jobs-default-card__link a:active {
    opacity: var(--hover-image-opacity);
  }
}
.o-jobs-default-card__link a[data-button=link] {
  color: var(--color-primary);
}
.o-jobs-default-card__link a[data-button=keep] {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: calc(16 / 16 * 1rem) auto;
  gap: calc(10 / 16 * 1rem);
  border-color: #049cd8;
  box-shadow: 0 calc(2 / 16 * 1rem) 0 #00638a;
}
.o-jobs-default-card__link a[data-button=keep].is-done {
  border-color: #f5f5f5;
  background: #f5f5f5;
  box-shadow: none;
  pointer-events: none;
}
.o-jobs-default-card__link a[data-button=keep].is-done > span {
  display: none;
}
.o-jobs-default-card__link a[data-button=keep].is-done:after {
  content: "検討中BOXに保存済";
}
.o-jobs-default-card__link a[data-button=keep]:before {
  content: "";
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  margin-block: auto;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M16,3.67c0-2.02-1.64-3.66-3.67-3.67H3.67C1.64,0,0,1.64,0,3.67v.67c0,.61.24,1.19.67,1.63v6.37c0,2.02,1.64,3.66,3.67,3.67h7.33c2.02,0,3.66-1.64,3.67-3.67v-6.37c.43-.43.67-1.02.67-1.63v-.67ZM2,3.67c0-.92.75-1.67,1.67-1.67h8.67c.92,0,1.67.75,1.67,1.67v.67c0,.18-.15.33-.33.33H2.33c-.18,0-.33-.15-.33-.33,0,0,0,0,0,0v-.67ZM13.33,12.33c0,.92-.75,1.67-1.67,1.67h-7.33c-.92,0-1.67-.75-1.67-1.67v-5.67h10.67v5.67Z"/><path fill="%23049cd8" d="M6.33,8h3.33c.55,0,1,.45,1,1h0c0,.55-.45,1-1,1h-3.33c-.55,0-1-.45-1-1h0c0-.55.45-1,1-1Z"/></svg>');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.o-jobs-default-card__link a[data-button=apply] {
  background: var(--color-primary);
  color: var(--color-white);
}

/******************************************************************************
* o-jobs-carousel
*******************************************************************************/
.o-jobs-carousel {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-carousel {
    margin-inline: calc(-20 / 16 * 1rem);
    overflow: hidden;
  }
}
.o-jobs-carousel .splide__arrows {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-carousel .splide__arrows {
    display: none;
  }
}
.o-jobs-carousel .splide__track {
  margin-inline: -2px !important;
}
.o-jobs-carousel .splide__arrow {
  width: 50px;
  height: auto;
  aspect-ratio: 50/54.3;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (any-hover: hover) {
  .o-jobs-carousel .splide__arrow:hover {
    opacity: 0.7;
  }
}
@media (any-hover: none) {
  .o-jobs-carousel .splide__arrow:active {
    opacity: 0.7;
  }
}
.o-jobs-carousel .splide__arrow--prev[disabled], .o-jobs-carousel .splide__arrow--next[disabled] {
  visibility: hidden;
}
.o-jobs-carousel .splide__arrow--prev {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 54.29"><rect fill="%23fff" y="0" width="50" height="54.28" rx="8" ry="8"/><path fill="%2361b7d9" d="M44.28,1.43c2.37,0,4.28,1.92,4.29,4.29v42.86c0,2.37-1.92,4.28-4.29,4.29H5.71c-2.37,0-4.28-1.92-4.29-4.29V5.71c0-2.37,1.92-4.28,4.29-4.29h38.57M44.28,0H5.71C2.56,0,0,2.56,0,5.71v42.86c0,3.16,2.56,5.71,5.71,5.71h38.57c3.16,0,5.71-2.56,5.72-5.71h0V5.71C50,2.56,47.44,0,44.29,0c0,0,0,0,0,0Z"/><path fill="%2361b7d9" d="M28.57,37.39c.57,0,1.11-.23,1.52-.63.84-.84.84-2.19,0-3.03l-6.59-6.59,6.59-6.59c.84-.84.84-2.19,0-3.03-.84-.84-2.19-.84-3.03,0l-8.11,8.11c-.4.4-.63.95-.63,1.52,0,.57.23,1.11.63,1.52l8.11,8.11c.4.4.95.63,1.52.63Z"/></svg>');
  left: calc(-65 / 16 * 1rem);
}
.o-jobs-carousel .splide__arrow--next {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 54.29"><rect fill="%23fff" width="50" height="54.29" rx="8" ry="8"/><path fill="%2361b7d9" d="M5.71,1.43c-2.37,0-4.28,1.92-4.29,4.29v42.86c0,2.37,1.92,4.28,4.29,4.29h38.57c2.37,0,4.28-1.92,4.29-4.29V5.71c0-2.37-1.92-4.28-4.29-4.29H5.71M5.71,0h38.57c3.16,0,5.71,2.56,5.71,5.71h0v42.86c0,3.16-2.56,5.71-5.71,5.71H5.71C2.56,54.28,0,51.73,0,48.57h0V5.71C0,2.56,2.56,0,5.71,0Z"/><path fill="%2361b7d9" d="M21.43,37.39c-.57,0-1.11-.23-1.52-.63-.84-.84-.84-2.19,0-3.03l6.59-6.59-6.59-6.59c-.84-.84-.84-2.19,0-3.03.84-.84,2.19-.84,3.03,0l8.11,8.11c.4.4.63.95.63,1.52,0,.57-.23,1.11-.63,1.52l-8.11,8.11c-.4.4-.95.63-1.52.63Z"/></svg>');
  right: calc(-65 / 16 * 1rem);
}
.o-jobs-carousel .splide__pagination {
  gap: calc(11 / 16 * 1rem);
  margin-top: calc(30 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-carousel .splide__pagination {
    gap: calc(6 / 16 * 1rem);
    margin-top: calc(20 / 16 * 1rem);
  }
}
.o-jobs-carousel .splide__pagination__page {
  display: block;
  width: calc(11 / 16 * 1rem);
  height: calc(11 / 16 * 1rem);
  background-color: var(--color-gray-cc);
}
.o-jobs-carousel .splide__pagination__page.is-active {
  border-color: #049cd8;
  background-color: var(--color-white);
}

/******************************************************************************
* o-jobs-carousel-double
*******************************************************************************/
.o-jobs-carousel-double {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-carousel-double {
    padding-left: calc(20 / 16 * 1rem);
    margin-inline: calc(-20 / 16 * 1rem);
  }
}
.o-jobs-carousel-double .splide__track {
  padding: 2px !important;
  margin: -2px !important;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-carousel-double .splide__track {
    overflow: visible;
  }
}
.o-jobs-carousel-double .splide__arrows {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-carousel-double .splide__arrows {
    display: none;
  }
}
.o-jobs-carousel-double .splide__arrow {
  width: 50px;
  aspect-ratio: 50/54.3;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  border-radius: 0;
  box-shadow: none;
}
@media (any-hover: hover) {
  .o-jobs-carousel-double .splide__arrow:hover {
    opacity: 0.7;
  }
}
@media (any-hover: none) {
  .o-jobs-carousel-double .splide__arrow:active {
    opacity: 0.7;
  }
}
.o-jobs-carousel-double .splide__arrow--prev {
  top: 48.4%;
  left: calc(-70 / 16 * 1rem);
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 54.29"><rect fill="%23fff" y="0" width="50" height="54.28" rx="8" ry="8"/><path fill="%2361b7d9" d="M44.28,1.43c2.37,0,4.28,1.92,4.29,4.29v42.86c0,2.37-1.92,4.28-4.29,4.29H5.71c-2.37,0-4.28-1.92-4.29-4.29V5.71c0-2.37,1.92-4.28,4.29-4.29h38.57M44.28,0H5.71C2.56,0,0,2.56,0,5.71v42.86c0,3.16,2.56,5.71,5.71,5.71h38.57c3.16,0,5.71-2.56,5.72-5.71h0V5.71C50,2.56,47.44,0,44.29,0c0,0,0,0,0,0Z"/><path fill="%2361b7d9" d="M28.57,37.39c.57,0,1.11-.23,1.52-.63.84-.84.84-2.19,0-3.03l-6.59-6.59,6.59-6.59c.84-.84.84-2.19,0-3.03-.84-.84-2.19-.84-3.03,0l-8.11,8.11c-.4.4-.63.95-.63,1.52,0,.57.23,1.11.63,1.52l8.11,8.11c.4.4.95.63,1.52.63Z"/></svg>');
}
.o-jobs-carousel-double .splide__arrow--prev:disabled {
  display: none;
}
.o-jobs-carousel-double .splide__arrow--next {
  top: 48.4%;
  right: calc(-70 / 16 * 1rem);
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 54.29"><rect fill="%23fff" width="50" height="54.29" rx="8" ry="8"/><path fill="%2361b7d9" d="M5.71,1.43c-2.37,0-4.28,1.92-4.29,4.29v42.86c0,2.37,1.92,4.28,4.29,4.29h38.57c2.37,0,4.28-1.92,4.29-4.29V5.71c0-2.37-1.92-4.28-4.29-4.29H5.71M5.71,0h38.57c3.16,0,5.71,2.56,5.71,5.71h0v42.86c0,3.16-2.56,5.71-5.71,5.71H5.71C2.56,54.28,0,51.73,0,48.57h0V5.71C0,2.56,2.56,0,5.71,0Z"/><path fill="%2361b7d9" d="M21.43,37.39c-.57,0-1.11-.23-1.52-.63-.84-.84-.84-2.19,0-3.03l6.59-6.59-6.59-6.59c-.84-.84-.84-2.19,0-3.03.84-.84,2.19-.84,3.03,0l8.11,8.11c.4.4.63.95.63,1.52,0,.57-.23,1.11-.63,1.52l-8.11,8.11c-.4.4-.95.63-1.52.63Z"/></svg>');
}
.o-jobs-carousel-double .splide__arrow--next:disabled {
  display: none;
}
.o-jobs-carousel-double .splide__pagination {
  gap: calc(11 / 16 * 1rem);
  margin-top: calc(30 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-jobs-carousel-double .splide__pagination {
    gap: calc(6 / 16 * 1rem);
    width: 95%;
    margin-top: calc(20 / 16 * 1rem);
  }
}
.o-jobs-carousel-double .splide__pagination__page {
  display: block;
  width: calc(11 / 16 * 1rem);
  height: calc(11 / 16 * 1rem);
  background-color: var(--color-gray-cc);
}
.o-jobs-carousel-double .splide__pagination__page.is-active {
  background-color: var(--color-white);
  border-color: #049cd8;
}

/******************************************************************************
* o-pickup-panel
*******************************************************************************/
.o-pickup-panel {
  min-width: 0;
  height: 100%;
}
.o-pickup-panel_link {
  display: flex;
  gap: calc(20 / 16 * 1rem);
  height: 100%;
  padding: calc(10 / 16 * 1rem);
  background-color: var(--color-white);
  border-radius: calc(5 / 16 * 1rem);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (any-hover: hover) {
  .o-pickup-panel_link:hover {
    opacity: var(--hover-image-opacity);
  }
}
@media (any-hover: none) {
  .o-pickup-panel_link:active {
    opacity: var(--hover-image-opacity);
  }
}
@media (min-width: 768px) {
  .o-pickup-panel_link {
    padding: calc(15 / 16 * 1rem);
  }
}
.o-pickup-panel_visual {
  position: relative;
  flex-shrink: 0;
  width: calc(90 / 16 * 1rem);
  height: calc(70 / 16 * 1rem);
  overflow: hidden;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-pickup-panel_visual {
    width: calc(100 / 16 * 1rem);
    height: calc(80 / 16 * 1rem);
  }
}
.o-pickup-panel_content {
  flex: 1;
}
.o-pickup-panel_text {
  display: -webkit-box;
  margin-top: calc(8 / 16 * 1rem);
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-pickup-panel_text {
    -webkit-line-clamp: 2;
  }
}

/******************************************************************************
* o-mainvisual
*******************************************************************************/
.o-mainvisual {
  position: relative;
  width: 100%;
  min-height: auto;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-mainvisual {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(280 / 16 * 1rem);
    padding: 0 calc(20 / 16 * 1rem);
    overflow: hidden;
  }
}
.o-mainvisual_content {
  position: relative;
  z-index: 2;
  padding: calc(30 / 16 * 1rem) calc(20 / 16 * 1rem) calc(140 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-mainvisual_content {
    width: 100%;
    max-width: calc(1120 / 16 * 1rem);
    height: 100%;
    padding: calc(30 / 16 * 1rem) 0;
  }
}
.o-mainvisual_content-tags {
  display: flex;
  flex-wrap: wrap;
  gap: calc(6 / 16 * 1rem);
}
.o-mainvisual_content-tags li {
  padding: 0.2em 0.8em;
  font-size: calc(14 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.1;
  color: var(--color-primary);
  background-color: var(--color-white);
  border-radius: calc(30 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-mainvisual_content-tags li {
    font-size: calc(16 / 16 * 1rem);
  }
}
.o-mainvisual_content-buttons {
  display: flex;
  gap: calc(10 / 16 * 1rem);
  margin-top: calc(20 / 16 * 1rem);
}
.o-mainvisual_content-buttons-item {
  width: 90%;
  height: calc(50 / 16 * 1rem);
  margin: 0 auto;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-mainvisual_content-buttons-item {
    width: fit-content;
    height: calc(50 / 16 * 1rem);
    margin: 0;
  }
}
.o-mainvisual_content-buttons-item .a-button.is-type-cv-primary {
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-mainvisual_content-buttons-item .a-button.is-type-cv-primary {
    padding: 0 calc(56 / 16 * 1rem);
  }
  .o-mainvisual_content-buttons-item .a-button.is-type-cv-primary .a-button_inner svg {
    position: absolute;
    top: 50%;
    right: calc(12 / 16 * 1rem);
    transition: transform var(--anime-duration) var(--anime-function);
    translate: 0 -50%;
    /* stylelint-disable-next-line media-query-no-invalid */
  }
}
@media (max-width: 767px) and (min-width: 768px) {
  .o-mainvisual_content-buttons-item .a-button.is-type-cv-primary .a-button_inner svg {
    right: calc(16 / 16 * 1rem);
  }
}
@media (min-width: 768px) {
  .o-mainvisual_content-buttons-item .a-button.is-type-cv-primary {
    padding: 0 calc(10 / 16 * 1rem) 0 calc(20 / 16 * 1rem);
  }
  .o-mainvisual_content-buttons-item .a-button.is-type-cv-primary .a-button_inner {
    display: flex;
    gap: calc(10 / 16 * 1rem);
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
.o-mainvisual_bg {
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-mainvisual_bg {
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: var(--color-primary);
  }
}
@media (min-width: 768px) {
  .o-mainvisual_bg {
    position: absolute;
    inset: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
  }
}
.o-mainvisual_bg-inner {
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-mainvisual_bg-inner {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .o-mainvisual_bg-inner {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: calc(1120 / 16 * 1rem);
    height: 100%;
  }
  .o-mainvisual_bg-inner::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: calc(-530 / 16 * 1rem);
    z-index: 2;
    display: block;
    width: calc(1210 / 16 * 1rem);
    height: 100%;
    content: "";
    background-color: var(--color-primary);
    transform: skewX(-22deg);
  }
}
.o-mainvisual_bg-decoration {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  overflow: hidden;
  font-size: calc(68 / 16 * 1rem);
  font-weight: 700;
  line-height: 1;
  color: var(--color-white);
  opacity: 0.1;
  /* stylelint-disable-next-line media-query-no-invalid */
}
.o-mainvisual_bg-decoration span {
  display: block;
  transform: translateY(calc(-10 / 16 * 1rem));
}
@media (min-width: 768px) {
  .o-mainvisual_bg-decoration {
    font-size: calc(88 / 16 * 1rem);
  }
}
.o-mainvisual_bg-image {
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-mainvisual_bg-image {
    position: relative;
    padding: 0 calc(20 / 16 * 1rem);
  }
  .o-mainvisual_bg-image::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    height: 50%;
    content: "";
    background-color: var(--color-white);
  }
}
@media (min-width: 768px) {
  .o-mainvisual_bg-image {
    position: relative;
    z-index: 1;
  }
}
.o-mainvisual_bg-image-inner {
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-mainvisual_bg-image-inner {
    position: relative;
    z-index: 3;
  }
}
@media (min-width: 768px) {
  .o-mainvisual_bg-image-inner {
    height: 100%;
    margin-right: calc(-390 / 16 * 1rem);
  }
}

/******************************************************************************
* o-under-link
*******************************************************************************/
.o-under-link {
  position: relative; /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-under-link {
    margin-top: calc(20 / 16 * 1rem);
  }
}
.o-under-link_list {
  display: flex;
  flex-direction: column;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-under-link_list {
    flex-direction: row;
    gap: calc(20 / 16 * 1rem);
    justify-content: space-between;
    height: calc(55 / 16 * 1rem);
  }
}
.o-under-link_list li {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-under-link_list li {
    height: calc(50 / 16 * 1rem);
    border-bottom: solid 1px var(--color-gray-cc);
  }
}
.o-under-link_item {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: calc(16 / 16 * 1rem);
  font-weight: 700;
  /* stylelint-disable-next-line media-query-no-invalid */
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (max-width: 767px) {
  .o-under-link_item:not(.is-current) {
    justify-content: space-between;
  }
  .o-under-link_item:not(.is-current)::after {
    display: block;
    width: calc(16 / 16 * 1rem);
    height: calc(16 / 16 * 1rem);
    content: "";
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="17.01" viewBox="0 0 9 17.01"><path d="M9,8.51c0,.28-.1,.57-.3,.78L1.77,16.69c-.41,.43-1.06,.43-1.47,0-.41-.43-.41-1.14,0-1.57l6.19-6.61L.3,1.89C-.1,1.46-.1,.76,.3,.33,.71-.11,1.37-.11,1.77,.33l6.92,7.4c.2,.22,.3,.5,.3,.78Z" style="fill:%2361b7d9; fill-rule:evenodd;"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
@media (min-width: 768px) {
  .o-under-link_item {
    font-size: calc(18 / 16 * 1rem);
    letter-spacing: normal;
  }
}
@media (any-hover: hover) {
  .o-under-link_item:hover {
    color: var(--hover-text-color);
  }
}
@media (any-hover: none) {
  .o-under-link_item:active {
    color: var(--hover-text-color);
  }
}
.o-under-link_item.is-current {
  color: var(--color-primary);
  pointer-events: none;
}
.o-under-link_item.is-current::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  height: calc(4 / 16 * 1rem);
  content: "";
  background-color: var(--color-primary);
}

/******************************************************************************
* o-under-heading
*******************************************************************************/
.o-under-heading {
  position: relative;
  padding-bottom: calc(30 / 16 * 1rem);
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="279.1" height="19.1" viewBox="0 0 279.1 19.1"><path d="M24.17,.87L0,19.1l209.54-7.8L279.1,0,24.17,.87Z" style="fill:%2361b7d9; fill-rule:evenodd;"/></svg>');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: calc(200 / 16 * 1rem) auto; /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-under-heading {
    padding-bottom: calc(36 / 16 * 1rem);
    background-size: calc(279 / 16 * 1rem) auto;
  }
}
.o-under-heading.is-white {
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="279.1" height="19.1" viewBox="0 0 279.1 19.1"><path d="M24.17,.87L0,19.1l209.54-7.8L279.1,0,24.17,.87Z" style="fill:%23fff; fill-rule:evenodd;"/></svg>');
}
.o-under-heading.is-white .a-heading {
  color: var(--color-white);
}

/******************************************************************************
* o-media-col
*******************************************************************************/
.o-media-col {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(60 / 16 * 1rem);
}
.o-media-col.is-center .o-media-col_item {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-media-col.is-center .o-media-col_item {
    align-items: center;
  }
}
.o-media-col_item {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(30 / 16 * 1rem);
}
.o-media-col.is-one-third .o-media-col_item {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-media-col.is-one-third .o-media-col_item {
    grid-template-columns: 1fr calc(400 / 16 * 1rem);
    gap: calc(40 / 16 * 1rem);
  }
  .o-media-col.is-one-third .o-media-col_item:nth-of-type(even) {
    grid-template-columns: calc(400 / 16 * 1rem) 1fr;
  }
  .o-media-col.is-one-third .o-media-col_item:nth-of-type(even) .o-media-col_visual {
    order: 1;
  }
  .o-media-col.is-one-third .o-media-col_item:nth-of-type(even) .o-media-col_content {
    order: 2;
  }
  .o-media-col.is-one-third .o-media-col_item.is-reverse {
    grid-template-columns: calc(400 / 16 * 1rem) 1fr;
  }
  .o-media-col.is-one-third .o-media-col_item.is-reverse .o-media-col_visual {
    order: 1;
  }
  .o-media-col.is-one-third .o-media-col_item.is-reverse .o-media-col_content {
    order: 2;
  }
}
.o-media-col.is-half .o-media-col_item {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-media-col.is-half .o-media-col_item {
    grid-template-columns: 1fr 1fr;
    gap: calc(40 / 16 * 1rem);
  }
  .o-media-col.is-half .o-media-col_item:nth-of-type(even) {
    grid-template-columns: 1fr 1fr;
  }
  .o-media-col.is-half .o-media-col_item:nth-of-type(even) .o-media-col_visual {
    order: 1;
  }
  .o-media-col.is-half .o-media-col_item:nth-of-type(even) .o-media-col_content {
    order: 2;
  }
}

/******************************************************************************
* contact
*******************************************************************************/
.o-contact {
  position: relative;
  padding: calc(30 / 16 * 1rem) calc(20 / 16 * 1rem);
  background-color: var(--color-primary);
  border-radius: calc(5 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-contact {
    padding: calc(50 / 16 * 1rem) calc(50 / 16 * 1rem) calc(40 / 16 * 1rem);
  }
}
.o-contact_head .a-heading {
  letter-spacing: normal;
}
.o-contact_separate {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(40 / 16 * 1rem);
  margin-top: calc(40 / 16 * 1rem);
  color: var(--color-white);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-contact_separate {
    grid-template-columns: repeat(2, 1fr);
    gap: calc(50 / 16 * 1rem);
    margin-top: calc(60 / 16 * 1rem);
  }
}
.o-contact_separate-left, .o-contact_separate-right {
  min-width: 0;
}
.o-contact_child {
  display: flex;
  flex-direction: column;
  gap: calc(10 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-contact_child {
    flex-direction: row;
    gap: calc(10 / 16 * 1rem);
  }
}
.o-contact_child-image {
  width: 100%;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-contact_child-image {
    width: calc(165 / 16 * 1rem);
  }
}
.o-contact_child-image img {
  width: 100%;
  height: auto;
}
.o-contact_child-content {
  flex: 1;
}
.o-contact_child-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(5 / 16 * 1rem);
  color: var(--color-text-base);
}
.o-contact_child-list li {
  display: flex;
  gap: calc(10 / 16 * 1rem);
  align-items: center;
  min-width: 0;
  height: calc(70 / 16 * 1rem);
  padding: 0 calc(20 / 16 * 1rem);
  font-weight: 700;
  line-height: 1.5;
  background-color: var(--color-white);
  border-radius: calc(5 / 16 * 1rem);
}
.o-contact_child-list li .a-image {
  flex-shrink: 0;
}
.o-contact_child-list li span {
  flex: 1;
}

/******************************************************************************
* o-more-info
*******************************************************************************/
.o-more-info {
  display: grid;
  grid-template-columns: 1fr;
  gap: calc(10 / 16 * 1rem);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-more-info {
    grid-template-columns: repeat(2, 1fr);
    gap: calc(20 / 16 * 1rem);
  }
}
.o-more-info.is-single {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-more-info.is-single {
    display: flex;
    justify-content: center;
  }
}
.o-more-info.is-single .o-more-info_item {
  border: solid 1px var(--color-gray-e5);
  box-shadow: none;
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-more-info.is-single .o-more-info_item {
    gap: calc(30 / 16 * 1rem);
    align-items: center;
    width: 100%;
    max-width: 820px;
    padding: calc(20 / 16 * 1rem);
  }
}
.o-more-info.is-single .o-more-info_visual {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-more-info.is-single .o-more-info_visual {
    width: calc(240 / 16 * 1rem);
  }
}
.o-more-info.is-single .o-more-info_content-button {
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-more-info.is-single .o-more-info_content-button {
    position: relative;
    right: auto;
    bottom: auto;
    z-index: 1;
    margin-top: calc(20 / 16 * 1rem);
  }
}
.o-more-info_item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: calc(20 / 16 * 1rem);
  min-width: 0;
  padding: calc(20 / 16 * 1rem) calc(20 / 16 * 1rem) calc(60 / 16 * 1rem);
  background-color: var(--color-white);
  border-radius: calc(5 / 16 * 1rem);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  /* stylelint-disable-next-line media-query-no-invalid */
}
@media (min-width: 768px) {
  .o-more-info_item {
    padding: calc(20 / 16 * 1rem) calc(20 / 16 * 1rem) calc(60 / 16 * 1rem);
  }
}
.o-more-info_visual {
  width: 100%;
  /* stylelint-disable-next-line media-query-no-invalid */
}
.o-more-info_visual img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .o-more-info_visual {
    width: calc(120 / 16 * 1rem);
  }
}
.o-more-info_content {
  flex: 1;
}
.o-more-info_content-button {
  position: absolute;
  right: calc(20 / 16 * 1rem);
  bottom: calc(20 / 16 * 1rem);
  z-index: 1;
}

@tailwind components;
@tailwind utilities;
/******************************************************************************
  * common
  *******************************************************************************/